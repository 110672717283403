.stockidea__stock-card-content.expanded {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background:rgb(218, 234, 212);
  filter: none;     
  z-index: 11;
  @apply p-2 shadow-2xl shadow-black 
}

.blur-background {
  filter: blur(8px);
  transition: filter 0.3s ease-in-out;
}

.expanded-card-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  @apply bg-slate-500
}

.stockidea__stock-card {
  cursor: pointer;
@apply max-sm:min-w-[84%] max-md:min-w-[75%] lg:min-w-[50%]
}

.stockidea__stock-card-content {
  transition: all 0.3s ease;
}


.pricing,.percent-pricing{
  @apply p-0 
}



.welcome-message {
  font-family: "Arial", sans-serif;
  background: linear-gradient(135deg, #ffffff 0%, #ffffff 100%);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 40px;
  margin-top: 5rem;
  text-align: center;
  position: relative;
}

.welcome-message:before {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  background: linear-gradient(135deg, #e8e8e8 0%, #f9f9f9 100%);
  z-index: -1;
  border-radius: 15px;
}

.welcome-message h2 {
  color: #000000;
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.welcome-message h2 i {
  margin-right: 15px;
  font-size: 28px;
  color: #ffc107;
}

.welcome-message p {
  color: #414040;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 25px;
}

.welcome-message p i {
  color: #ff5252;
}

.Stockidea_header_div {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  margin: 0px;
}

.Tilte-text-stockIdeas {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
  margin-top: 0px;
  color: black;
  padding: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.5;
}

.stockIdea__dashboard_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 5px;
  margin-top: 5px;
  margin-bottom: 10px;
  background-color: #ffffff;
}

.stockidea__userSubscription_name,
.stockidea__totalActiveStock_container,
.stockIdea__totalprofit_loss_main,
.stockIdea__currentinvestment,
.stockIdea__remainingInvestment {
  padding: 5px 30px;
  border-radius: 30px;
  cursor: default;
}

.dashborad_stockideadata {
  margin-left: 10px;
}

.stockIdea__totalprofit_loss_main {
  display: flex;
  align-items: center;
}

.stockIdea__editIcons__dashboard {
  margin-left: 10px !important;
  font-size: 20px !important;
  padding: 0px !important;
}

.stockIdea__editIcons__dashboard:hover {
  color: white !important;
}

.stockidea-reset-profit-dialog {
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 1500px;
  max-width: 100%;
  margin: 10px auto;
  background-color: #34323290;
  overflow: hidden;
}

.stockidea-reset-profit-dialog-title {
  margin: 0px;
  padding: 5px;
  background-color: #af1010;
  width: 500px;
  color: #fdfdfd;
  font-size: 24px;
  text-align: center;
}

.stockidea-reset-profit-dialog-content {
  padding: 20px;
  line-height: 1.5;
  font-size: 16px;
  color: #ed0303;
  margin-top: 20px;
  text-align: center;
}

.stockidea-reset-profit-dialog-text {
  margin-bottom: 20px;
  color: red !important;
}

.stockidea-reset-profit-dialog-actions {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  border-top: 1px solid #ccc;
}

.stockidea-reset-profit-dialog-cancel,
.stockidea-reset-profit-dialog-confirm {
  border: 1px solid black !important;
  padding: 10px 20px;
  border-radius: 30px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.stockidea-reset-profit-dialog-cancel {
  background-color: #e81111 !important;
  color: white !important;
}

.stockidea-reset-profit-dialog-cancel:hover {
  background-color: #ff0000 !important;
}

.stockidea-reset-profit-dialog-confirm {
  background-color: #28a745 !important;
  color: white !important;
}

.stockidea-reset-profit-dialog-confirm:hover {
  background-color: #218838 !important;
}

.investment_input_div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-bottom: 2px dotted rgb(162, 162, 162);
}

.investment_input_div .investment-input-main-btn {
  background: linear-gradient(to right, #369638, #57b757);
  color: white;
  border-radius: 30px;
  padding: 5px 30px;
  font-size: 14px;
  text-align: center;
  width: auto;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 10px;
  margin-left: auto;
  transition: background-color 0.3s ease;
  text-transform: none;
}

.investment_input_div .investment-input-main-btn:hover {
  background: linear-gradient(to right, #369638, #2d712d);
}

.investment_input_div .investment-input-main-btn .investmetnvalueEdit_icon {
  color: white !important;
}

.investment_input_div .investment-input-popup-container {
  max-width: 600px;
  margin: auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.investment_input_div .button-style__achivedidea_button {
  background: linear-gradient(to right, #369638, #57b757);
  color: white;
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 16px;
  text-align: center;
  width: auto;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 10px;
  margin-left: auto;
  transition: background-color 0.3s ease;
}

.investment_input_div .button-style__achivedidea_button:hover {
  background: linear-gradient(to right, #369638, #2d712d);
}

.stockIdea__totalprofit_loss {
  background: linear-gradient(to right, #369638, #57b757);
  color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  width: auto;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 0px;
}

.totalprofit__title {
  font-weight: 600;
}

.stockIdea__totalprofit_loss:hover {
  background: linear-gradient(to right, #369638, #2d712d);
}

.toggle-container__stockidea {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px;
  border-bottom: 1px solid #ccc;
}

.Showtable__stockIdea_title,
.Showcard__stockIdea_title {
  margin: 0 10px;
  font-weight: bold;
  color: #333;
}

@media (max-width: 768px) {
  .stock-ideas-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 2.5rem;
    padding-top: 5rem;
    position: relative;
  }

  .Stockidea_header_div {
    padding: 8px 0;
  }

  .Tilte-text-stockIdeas {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #777;
    padding: 5px;
  }

  .stockidea__userSubscription_name,
  .stockidea__totalActiveStock_container,
  .stockIdea__totalprofit_loss_main,
  .stockIdea__currentinvestment,
  .stockIdea__remainingInvestment {
    padding: 5px 30px;
    background: linear-gradient(to right, #5cc3a2 0%, #547b66 100%);
    border-radius: 30px;
    cursor: default;
    flex: 1 1 auto;
    min-width: 150px;
    @apply shadow-inner shadow-slate-500 border-2 border-white
  }

  .dashborad_stockideadata_prift_loss {
    font-size: 14px;
    color: white !important;
  }

  .stockIdea__totalprofit_loss_main {
    justify-content: center;
  }

  .stockIdea__totalprofit_loss:hover {
    background: linear-gradient(to right, #17a2b8, #6610f2);
  }

  .stockIdea__staricon,
  .stockIdea__bulbicon,
  .stockidea__updownIcon,
  .stockidea__walletIcons {
    font-size: 22px !important;
  }

  @media screen and (max-width: 768px) {
    .investment_input_div {
      flex-wrap: wrap;
      justify-content: center;
      padding: 5px;
    }

    .investment_input_div .investment-input-main-btn,
    .investment_input_div .button-style__achivedidea_button,
    .stockIdea__totalprofit_loss {
      flex: 0 1 auto;
      margin: 5px;
      padding: 8px 15px;
      font-size: 14px;
    }

    .investment_input_div .investment-input-popup-container {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
      margin-top: 10px;
    }
  }
}


.stock_idea-Ls {
  background-image: linear-gradient(135deg, #492783, #8fd3b4);
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 5px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.stock_idea-Ls:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.stock_idea-Ls h3 {
  color: #ffffff;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.5px;
}

.stockIdea__date_card {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;

  justify-content: flex-start;
  gap: 10px;
}

.StockIdea_date_container {
  flex: 0 0 16%;
  cursor: pointer;
  background: linear-gradient(to right, #81cb11, #25fca2);
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  text-align: center;
  margin: 5px;
  height: 100px;
}

.StockIdea_date_container:hover {
  transform: translateY(-5px);
  border-color: #007bff;
}

.StockIdea_card_heading-date {
  font-size: 1.5em;
  color: #000000;
  margin-bottom: 5px;
  font-weight: bold;
}

.StockIdea_stock-date {
  font-size: 1.1em;
  font-weight: bold;
  color: #333;
}

.ribbon {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 30px;
  background: linear-gradient(145deg, #94b5e9, #98e8c0);
  color: #000000;
  font-weight: bold;
  font-size: 0.8em;
  border-radius: 0 0 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
}

.stockidea__stock-card {
  position: relative;
  overflow: hidden;
}

.ribbon-two {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -60px;
  transform: rotate(30deg);
  background: #ff6347;
  color: white;
  padding: 7px 70px;
  font-weight: 700;
  margin-top: 10px;
}

.buy {
  background: linear-gradient(135deg, #4caf50, #087f23);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: white;
}

.sell {
  background: linear-gradient(135deg, #f44336, #b71c1c);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: white;
}

.hold {
  background: linear-gradient(135deg, #ffeb3b, #fdd835);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  color: #333;
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}



.stockidea__stock-card {
@apply  border-2 border-white p-1 shadow-2xl shadow-slate-700 rounded-xl transition-all duration-100 ease-in-out hover:-translate-y-2
}

.StockIdea_card_header_companyName {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.stock-company h5 {
  margin: 0;
  color: #010101;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.3s ease;
  text-decoration: none;
  font-size: 18px;
  margin-top: 10px;
}

.stock-sector {
  color: #0e5715;
  font-size: 0.8em;
  font-weight: 400;
  margin-top: 15px;
}

.stock-shariah-status {
  display: inline-block;
  font-size: 0.9rem;
  padding: 5px 20px;
  border-radius: 25px;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.stock-shariah-status:hover {
  transform: scale(1.05);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
}

.stock-shariah-status.Compliant {
  background: linear-gradient(145deg, #6dc36d, #0c5e0f);
  color: #fff;
  border: 1px solid #4caf50;
}

.stock-shariah-status.Non-Compliant {
  background: linear-gradient(145deg, #f66969, #f44336);
  color: #fff;
  border: 1px solid #f44336;
}

.stock-info-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.stock-current-price {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stock-price-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 500px;
}

.current-price-component h4,
.price-change {
  width: 150px;
  font-weight: bold;
  font-size: 1rem;
}

.price-change p {
  margin-left: 2px;
  font-size: 1rem;
  margin-top: 1rem;
}

.price-change.positive {
  color: #4caf50;
}

.price-change.negative {
  color: #f44336;
}

@media (max-width: 768px) {
  .stock-shariah-status {
    font-size: 0.8rem;
    padding: 3px 20px;
    border-radius: 20px;
  }

  .stock-info-group {
    gap: 5px;
    justify-content: space-between;
  }

  .stock-current-price,
  .stock-price-details {
    width: 70%;
    justify-content: space-between;
    gap: 5px;
  }

  .current-price-component h4,
  .price-change {
    font-size: 0.9rem;
  }

  .price-change p {
    font-size: 0.8rem;
    margin-top: 0;
  }
}

.stock-recomendation-buy-title,
.stock-recomendation-buy-data {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5px 0;
}

.recommendation-label,
.buy-option-label,
.recommendation-data,
.buy-option-data {
  margin-right: 0px;
  font-weight: 500;
}

.recommendation-label,
.buy-option-label {
  padding: 2px 30px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #f5f7fa;
  font-weight: bold;
}

.buy-option-label {
  padding: 2px 30px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  background-color: #f5f7fa;
  font-weight: bold;
  margin-right: 20px;
}

.recommendation-data {
  padding: 3px 30px;
  border-radius: 30px;
  font-size: 0.8rem;
  font-weight: bold;
  transition: all 0.3s ease;
}

.recommendation-data.buy {
  background: linear-gradient(145deg, #6dc36d, #1a551c);
  color: #fff;
  @apply shadow-inner shadow-green-800 text-sm
}

.recommendation-data.sell {
  background: linear-gradient(145deg, #f66969, #f44336);
  color: #fff;
  @apply shadow-inner shadow-red-700 text-sm
}

.recommendation-data.hold {
  background: linear-gradient(145deg, #ffee58, #ffeb3b);
  color: #1d1b1b;
  @apply shadow-inner shadow-yellow-700 text-sm
}

.buy-option-data {
  padding: 5px 30px;
  border-radius: 20px;
  font-size: 0.8rem;
  transition: all 0.3s ease;
  background-color: white;
  color: #555;
  margin-right: 0px;
  border: 1px solid black;
  font-weight: bold;
}

@media (max-width: 768px) {
  .stock-recomendation-buy-title,
  .stock-recomendation-buy-data {
    padding: 3px 0;
  }

  .recommendation-label,
  .buy-option-label {
    padding: 2px 20px;
    font-size: 0.7rem;
    margin-right: 10px;
  }

  .recommendation-data {
    padding: 3px 20px;
    font-size: 0.7rem;
  }

  .buy-option-data {
    padding: 3px 20px;
    font-size: 0.7rem;
  }
}

.second-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  background: linear-gradient(145deg, #e9eff8, #f5f8ff);
  border-radius: 12px;
  margin-top: 10px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.1);
  border: 1px solid #d9e0e9;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.second-row:hover {
  transform: translateY(-3px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

.stock-details,
.stock-details-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
}

.stock-details > span {
  font-weight: 600;
  color: #090a0a;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  padding: 2px;
}

.stock-details-data > span {
  font-weight: 600;
  color: #090a0a;
  margin-bottom: 5px;
  font-size: 0.9rem;
  letter-spacing: 0.5px;
  padding: 2px;
}

.stock-details > span:last-child,
.stock-details-data > span:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .second-row {
    padding: 5px 12px;
    border-radius: 8px;
    margin-top: 5px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  }

  .second-row:hover {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  .stock-details,
  .stock-details-data {
    width: 100%;
  }

  .stock-details > span,
  .stock-details-data > span {
    font-size: 0.8rem;
    margin-bottom: 3px;
    padding: 1px;
  }
}

.recomended-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}

.stockideaCard__recommeded_investment {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 5px;
}

.recomended-percentage {
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background: conic-gradient(#4caf50 calc(var(--percentage) * 1%), #f7f7f7 0);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  border: 2px solid #282626;
  @apply shadow-inner shadow-slate-400 border-2 border-white
}

.recommended-percentage::before{
  content: attr(data-percentage) "%";
  font-size: 1.1em;
  font-weight: bold;
  color: #050505;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}
.recomended-percentage::before {
  content: attr(data-percentage) "%";
  font-size: 1.1em;
  font-weight: bold;
  color: #050505;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

.recomended-text {
  font-size: 1rem;
  color: #666;
  font-weight: 700;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}

.share-text-div {
  font-size: 1rem;
  color: #0a7c0e;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.05);
}
.NetGain-div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background-color: rgb(255, 255, 255);
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
}

.stockideacard__profitloss {
  font-weight: 700;
  color: #444;
  font-size: 1rem;
  letter-spacing: 1px;
  display: inline-block;
  margin-right: 5px;
}

.net-gain-component {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.net-gain-component h4 {
  margin: 0 6px;
  font-weight: 600;
  transition: color 0.3s ease;
}

.net-gain-component .positive {
  color: #00c851;
}

.net-gain-component .negative {
  color: #ff3b30;
}

.net-gain-component span {
  margin: 0 8px;
  color: #aaa;
}

.net-gain-component p {
  color: #888;
  font-style: italic;
}

.stock-message {
  font-size: 1rem;
  color: #000000;
  font-weight: 800;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  margin-top: 5px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  margin-bottom: 0px;
}

.blurred {
  filter: blur(3.5px);
}

.skeleton-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.skeleton-card {
  width: 210px;
  padding: 1rem 0;
}

.skeleton-container {
  padding: 1rem;
}

.skeleton-card {
  margin: 1rem 0;
}

.locked-sector {
  position: relative;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  background: #000;
  color: black;
}

.lock-icon-sector {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1rem;
  color: rgb(254, 247, 247);
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 50%;
  padding: 5px;
  z-index: 1;
}

.investment-value-input-wrapper__stockCard {
  @apply flex items-center justify-center
 }

.investment-value-input-wrapper__stockCard .investment-input-main-btn {
  background: linear-gradient(to right, #369638, #57b757);
  color: white;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  text-align: center;
  width: auto;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: none;
  margin: 10px;
  margin-left: auto;
  transition: background-color 0.3s ease;
}

.investment-value-input-wrapper__stockCard .investment-input-main-btn:hover {
  background: linear-gradient(to right, #369638, #2d712d);
}

.investment-value-input-wrapper__stockCard
  .investment-input-main-btn
  .investmetnvalueEdit_icon {
  color: white !important;
}

.investment-value-input-wrapper__stockCard .investment-input-popup-container {
  max-width: 600px;
  margin: auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .investment-value-input-wrapper__stockCard .investment-input-main-btn {
    padding: 8px 50px;
    font-size: 14px;
    margin: 5px;
  }

  .investment-value-input-wrapper__stockCard .investment-input-popup-container {
    max-width: 100%;
    padding: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  }
}

.recomendationdate {
  font-size: 14px;
  font-weight: 900;
  color: #000000;
  background: #f4f4f4;
  padding: 5px 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.stockideacard__portentialleft {
  margin-top: 5px;
}

.potential-design {
  @apply flex justify-center rounded-xl text-green-950 max-sm:text-sm 
}

.potential-percentage {
  font-size: 1rem;
  font-weight: bold;
  color: #4caf50;
}

.potential-error {
  color: #f44336;
  font-size: 14px;
  margin-top: 5px;
}

.view-lock-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}

.display-label {
  font-weight: 600;
  color: #555;
}

.view-mode-form-control {
  flex-grow: 1;
  margin-right: 25px;
}

.view-mode-form-control .MuiOutlinedInput-root {
  border-radius: 8px;
  background-color: #ffffff;
}

.MuiSelect-root {
  padding: 10px 30px 10px 12px;
}

.view-mode-option {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
}

.view-mode-option svg {
  margin-right: 10px;
  fill: #2196f3;
}

.lock-switch-control-label {
  display: flex;
  align-items: center;
}

.lock-switch {
  position: relative;
  margin-left: 20px;
}

.lock-switch .MuiSwitch-track {
  background-color: #e0e0e0;
}

.unlockicon,
.lockIcon {
  color: #555;
}

.lock-switch.MuiSwitch-colorPrimary.Mui-checked .MuiSwitch-thumb {
  color: #fff;
  background-color: #2196f3;
}

.lock-switch.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #64b5f6;
}

.lock-switch-control-label .MuiTypography-root {
  font-size: 0.9rem;
  font-weight: 500;
  color: #555;
}

.stock-idea-details-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
}

.Stock-idea-grid-details {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3em;
  color: #0f1216;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding: 10px;
  border-radius: 8px;
}

.date-title_for_unsubscribed {
  font-size: 1.8rem;
  font-weight: bold;
  color: #26d89a;
  text-align: center;
  text-transform: uppercase;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 2px solid black;
  background-color: #453f6a;
  padding: 10px;
}

.reset-profit-dialog {
  max-width: 400px;
  margin: auto;
}

.reset-profit-dialog-title {
  background-color: #e06c2e;
  color: #ffffff;
  padding: 10px;
  font-weight: 900;
  border-bottom: 1px solid #e0e0e0;
}

.reset-profit-dialog-content {
  padding: 15px;
}

.reset-profit-dialog-text {
  font-size: 16px;
  color: #555;
}

.reset-profit-dialog-cancel {
  color: #777 !important;
}

.reset-profit-dialog-confirm {
  color: #ffffff !important;
  background-color: red !important;
}

@media only screen and (max-width: 768px) {
  .stock-idea-details-container {
    flex-direction: column;
    align-items: stretch;
  }

  .Stock-idea-grid-details {
    font-size: 0.9em;
    padding: 10px;
    background: linear-gradient(135deg, #f7f7f9, #e0e0e0);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.3px;
    margin-top: 10px;
  }

  .dates-flex-container {
    padding: 10px;
    gap: 10px;
  }

  .date-card {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }

  .date-button {
    width: 100%;
    padding: 10px 0;
    margin-right: 0;
    margin-bottom: 1rem;
    font-size: 0.9em;
  }

  .heading-date {
    font-size: 0.9em;
  }

  .stock-datee {
    font-size: 1.8em;
  }

  .date-title_for_unsubscribed {
    font-size: 1rem;
  }
}



.potential-new{
  @apply flex flex-col text-lg shadow-inner shadow-slate-500 p-2 rounded-lg max-md:text-sm max-md:flex-row
}
