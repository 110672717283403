
.sold_portfolio {
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 3rem;
}

.sold_portfolio .MuiTableHead-root .MuiTableCell-head {
    position: sticky;
    top: 0;
    color: white !important;
    background-color: #2e3b4e !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: center;
    font-size: 2rem;
}
.sold_portfolio .tss-bd2lw8-MUIDataTableHeadCell-sortActive  {
    color: white !important; 
  }

.sold-portfolio-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

.portfolioNamecom-link {
    color: #8e006b;
    text-decoration: none;
    font-size: 1rem;
    font-weight: bold;
}

.portfolioNamecom-link:hover {
    text-decoration: underline;
}

.buy-price-value,
.quantity-value,
.sold-price-value,
.purgable-income-value {
    font-size: 1rem;
    color: #000000;
    text-align: center;
    font-weight: bold;
}

.profit-loss {
    font-size: 1rem;
    font-weight: bold;
}

.profit-loss.green {
    color: green;
}

.profit-loss.red {
    color: red;
}

.action-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.action-button:hover {
    background-color: #d62c1a;
    transform: translateY(-1px);
}

.action-button:disabled {
    background-color: #cccccc;
    cursor: default;
    opacity: 0.6;
}

.edit-icon,
.delete-icon {
    margin-right: 10px;
    cursor: pointer;
}


@keyframes dotAnimation {
    0% { content: "Deleting"; }
    25% { content: "Deleting."; }
    50% { content: "Deleting.."; }
    75% { content: "Deleting..."; }
    100% { content: "Deleting"; }
  }
  
  .deletingText::before {
    content: "Deleting";
    animation: dotAnimation 2s infinite;
  }

  .delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.delete-dialog-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.delete-dialog-content p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #333;
}

.delete-dialog-buttons {
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
}

.delete-dialog-cancel, .delete-dialog-confirm {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    outline: none;
}

.delete-dialog-cancel {
    background-color: #f44336;
    color: #fff;
}

.delete-dialog-confirm {
    background-color: #4CAF50;
    color: #fff;
}

.delete-dialog-cancel:hover {
    background-color: #e53935;
    transform: scale(1.05);
}

.delete-dialog-confirm:hover {
    background-color: #43a047;
    transform: scale(1.05);
}