.ticker-wrap {
  width: 100%;
  overflow: hidden;
  bottom: 80px;
  background-color: var(--primary-container);
  padding: 5px 0;
  z-index: 9999;
}

.ticker {
  display: inline-block;
  white-space: nowrap;
  animation: ticker 50s linear infinite;
}

.ticker:hover {
  animation-play-state: paused;
}

@keyframes ticker {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.index-item {
  display: inline-block;
  padding: 0 20px;
  color: #ffffff;

}

.index-item .item-name {
  margin: 0 5px;
  display: inline;
  white-space: nowrap;
  color: #ffffff;
  border: 1px solid white;
  font-size: 0.8rem;
  background: var(--primary-container);
}

.index-item .item-value {
  margin: 0 5px;
  display: inline;
  white-space: nowrap;
  color: #ffffff;
 
}



.index-item .item-change.positive,
.index-item .icon.positive {
  color: var(--on-primary-container);

}

.index-item .item-change.negative,
.index-item .icon.negative {
  color: hsl(0, 80%, 60%);

}