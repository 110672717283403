.report-page-container {
  position: relative;
  z-index: 4;
  backgound-color: var(--surface);
}

.finview_main__button-container {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  position: sticky;
  top: 60px;
  background-color: var(--surface);
  z-index: 5; /* Ensure this is lower than .custom-menu */
  padding: 0.5rem;
}

.finview_main__button-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  margin: 0 10px;
  border: none;
  border-radius: 25px;
  color: var(--on-primary);
  font-weight: 600;
  background-color: var(--primary);
  cursor: pointer;
  transition: background-color 0.3s;
  border: 1px solid whitesmoke;
}
.finview_main__button-container button svg {
  margin-right: 8px;
}

.button-market:hover,
.button-research:hover,
.button-ipo:hover {
  background-color: var(--primary-variant);
  color: var(--on-primary-variant);
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  transform: translateY(-2px) scale(1.03);
}

.button-market.active,
.button-research.active,
.button-ipo.active {
  background: var(--primary-fixed);
  color: var(--on-primary-fixed);
  font-weight: semibold;
  border-radius: 25px;
  transition: all 0.3s ease-in-out;
  transform: translateY(-3px) scale(1.05);
}

.columns-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  backgound-color: var(--surface);
}

.columns-container .column {
  flex: 1;
  background-color: var(--surface);
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 768px) {
  .report-page-container {
    margin-top: 0;
  }

  .finview_main__button-container {
    display: flex;
    justify-content: flex-start; /* Align buttons to the left */
    flex-wrap: nowrap; /* Prevent buttons from wrapping */
    overflow-x: auto; /* Allow horizontal scrolling if needed */
    gap: 8px; /* Adjusted spacing between buttons */
    margin-bottom: 8px; /* Slightly smaller bottom margin */
    padding: 0.5rem;
  }

  .finview_main__button-container button {
    padding: 6px 10px; /* Smaller padding for compact buttons */
    font-size: 12px; /* Reduce font size for better fit */
    margin: 0; /* Remove unnecessary margins */
    white-space: nowrap; /* Prevent text wrapping */
    flex-shrink: 0; /* Ensure buttons don't shrink */
    border-radius: 20px; /* Smaller, compact border radius */
  }

  .lock-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: auto;
    color: white;
  }

  .lock-button .lock-icon,
  .lock-button .unlock-icon {
    font-size: 0.9rem; /* Smaller icon size for mobile */
    margin: 0 4px; /* Adjusted spacing */
  }

  .columns-container {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
    gap: 8px; /* Smaller gap between columns */
    backgound-color: var(--surface);
  }

  .columns-container .column {
    flex: 1;
  }
}
