.text {
  background-color: var(--primary-container);
}

.text--color-default {
  color: var(--on-surface);
}
.text--color-primary {
  color: var(--primary);
}
.text--color-variant {
  color: var(--on-surface-variant);
}
.text--color-low {
  color: var(--on-surface-low);
}
.text--color-inverse {
  color: var(--on-surface-inverse);
}

.text--display-medium {
  font-family: "Funnel Display";
  font-size: 56px;
  font-weight: var(--font-weight-display);
  line-height: 130%;
}
.text--heading-medium {
  font-family: "Funnel Display";
  font-size: 40px;
  font-weight: var(--font-weight-heading);
  line-height: 120%;
}
.text--title-medium {
  font-family: "Funnel Sans";
  font-size: 24px;
  font-weight: var(--font-weight-title);
  line-height: 140%;
}
.text--body-medium {
  font-family: "Funnel Sans";
  font-size: 20px;
  font-weight: var(--font-weight-body);
  line-height: 160%;
}
.text--label-large {
  font-family: "Funnel Sans";
  font-size: 18px;
  font-weight: var(--font-weight-label);
}
.text--label-medium {
  font-family: "Funnel Sans";
  font-size: 16px;
  font-weight: var(--font-weight-label);
}
.text--label-small {
  font-family: "Funnel Sans";
  font-size: 14px;
  font-weight: var(--font-weight-label);
}

.text--margin-none {
  margin-bottom: 0em;
}
.text--margin-xs {
  margin-bottom: 0.38em;
}
.text--margin-sm {
  margin-bottom: 0.62em;
}
.text--margin-md {
  margin-bottom: 1em;
}
.text--margin-lg {
  margin-bottom: 1.62em;
}
.text--margin-xl {
  margin-bottom: 2.62em;
}
.text--margin-2xl {
  margin-bottom: 4.2em;
}
.text--margin-3xl {
  margin-bottom: 6.8em;
}

.text--highlight {
  color: var(--on-primary-container);
  background-color: var(--primary-container);
  border-radius: 16px;
}

@media (max-width: 768px) {
  .text--display-medium {
    font-size: 40px; /* Reduced for mobile screens */
    line-height: 120%;
  }

  .text--heading-medium {
    font-size: 32px; /* Adjusted for smaller screens */
    line-height: 115%;
  }

  .text--title-medium {
    font-size: 20px; /* Slightly reduced */
    line-height: 130%;
  }

  .text--body-medium {
    font-size: 18px; /* Scaled down */
    line-height: 150%;
  }

  .text--label-large {
    font-size: 16px;
  }

  .text--label-medium {
    font-size: 14px;
  }

  .text--label-small {
    font-size: 12px;
  }

  /* Center text in mobile view if needed */
  .text--center {
    text-align: center;
  }
}
