.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  background-color: var(--surface-container-low);
  border-radius: 16px;
  border: none;
  box-sizing: border-box; /* Ensure padding and width calculations are accurate */
}

@media (max-width: 768px) {
  .card {
    padding: 12px; /* Reduce padding for smaller screens */
    border-radius: 12px; /* Make corners slightly compact */
    gap: 8px; /* Adjust the gap for children */
    font-size: 0.9rem; /* Scale down font size for better fit */
    line-height: 1.4; /* Adjust line spacing for readability */
    word-wrap: break-word; /* Prevent text overflow */
    max-width: 90%; /* Ensure card doesn't exceed the viewport width */
    margin: 0 auto; /* Center the card horizontally */
  }
  .flex-row {
    gap: 4px; /* Reduce gap between stars for smaller screens */
  }

  p {
    margin: 0; /* Reset paragraph margins to avoid extra spacing */
    word-break: break-word; /* Ensure text breaks properly */
  }

  label {
    font-size: 0.85rem; /* Slightly smaller font size for labels */
    line-height: 1.3; /* Adjust line height for labels */
  }
}
