:root{
    --space-none: 0px;
    --space-xs: 8px;
    --space-sm: 12px;
    --space-md: 16px;
    --space-lg: 24px;
    --space-xl: 32px;
    --space-2xl: 48px;
    --space-3xl: 64px;
    --space-4xl: 80px;
    --space-5xl: 96px;
}

.gap-none{
    gap: var(--space-none);
}
.gap-xs{
    gap: var(--space-xs);
}
.gap-sm{
    gap: var(--space-sm);
}
.gap-md{
    gap: var(--space-md);
}
.gap-lg{
    gap: var(--space-lg);
}
.gap-xl{
    gap: var(--space-xl);
}
.gap-2xl{
    gap: var(--space-2xl);
}
.gap-3xl{
    gap: var(--space-3xl);
}
.gap-4xl{
    gap: var(--space-4xl);
}
.gap-5xl{
    gap: var(--space-5xl);
}

.padding-none{
    padding: var(--space-none);
}
.padding-xs{
    padding: var(--space-xs);
}
.padding-sm{
    padding: var(--space-sm);
}
.padding-md{
    padding: var(--space-md);
}
.padding-lg{
    padding: var(--space-lg);
}
.padding-xl{
    padding: var(--space-xl);
}
.padding-2xl{
    padding: var(--space-2xl);
}
.padding-3xl{
    padding: var(--space-3xl);
}
.padding-4xl{
    padding: var(--space-4xl);
}
.padding-5xl{
    padding: var(--space-5xl);
}

.padding-x-none{
    padding-left: var(--space-none);
    padding-right: var(--space-none);
}
.padding-x-xs{
    padding-left: var(--space-xs);
    padding-right: var(--space-xs);
}
.padding-x-sm{
    padding-left: var(--space-sm);
    padding-right: var(--space-sm);
}
.padding-x-md{
    padding-left: var(--space-md);
    padding-right: var(--space-md);
}
.padding-x-lg{
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
}
.padding-x-xl{
    padding-left: var(--space-xl);
    padding-right: var(--space-xl);
}
.padding-x-2xl{
    padding-left: var(--space-2xl);
    padding-right: var(--space-2xl);
}
.padding-x-3xl{
    padding-left: var(--space-3xl);
    padding-right: var(--space-3xl);
}
.padding-x-4xl{
    padding-left: var(--space-4xl);
    padding-right: var(--space-4xl);
}
.padding-x-5xl{
    padding-left: var(--space-5xl);
    padding-right: var(--space-5xl);
}