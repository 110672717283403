.Research_and_marketNews_container {
  font-family: "Roboto", sans-serif;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 0px;
  padding: 10px;
  background-color: var(--surface);
}

.report-container {
  position: relative;
  flex: 1 1 30%;
  min-width: 320px;
  max-width: calc(33.333% - 20px);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 22px;
  margin-right: 20px;
  margin-bottom: 15px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid var(--on-surface);
}

.report-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.Research_report_title {
  display: flex;
  align-items: center;
  color: var(--on-surface);
  margin-bottom: 10px;
  font-size: 1.5rem;
  font-weight: 800;
  padding: 10px;
  width: 100%;
  margin-top: 0;
}

.Research_report_title svg {
  margin-left: 8px;
}

.report-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.reportView_companyName {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--on-surface);
}

.price-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  margin-bottom: 0.5rem;
  padding: 7px;
  border-radius: 25px;
  width: 100%;
}

.report_price h4 {
  font-weight: bold;
  color: var(--on-surface);
  font-size: 14px;
  margin: 0;
  padding: 10px;
}

.report_price_percentage p {
  font-weight: bold;
  color: #06d001;
  font-size: 12px;
  margin: 0;
  text-align: left;
}

.status-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.shariah-status {
  padding: 5px;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
}

.green-text {
  background: linear-gradient(to right, #2ecc71, #27ae60);
  color: var(--on-surface);
}

.red-text {
  background: linear-gradient(to right, #e74c3c, #c0392b);
  color: var(--on-surface);
}

.research-star-rating .star {
  font-size: 24px;
}

.research-star-rating .filled {
  color: gold;
}

.research-star-rating .half {
  color: gold;
}

.research-star-rating .low {
  color: red;
}

.report-date_researchView {
  font-size: 12px;
  color: var(--on-surface-low);
  text-align: end;
  font-weight: 500;
}

.reasearc_view_Readmore_text_div {
  margin-left: 20px;
}

.research_view_readmoretext {
  font-size: 15px;
  text-align: right;
  /* color: rgb(18, 167, 35); */
  color: var(--primary);
  font-weight: 600;
  cursor: pointer;
}

.no-reports-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  max-width: 1500px;
  margin: 10px auto;
}

.no-reports-message {
  font-size: 18px;
  color: var(--on-surface);
  margin-bottom: 20px;
}

.no-reports-wrench-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: #3498db;
  margin-bottom: 10px;
}

.no-reports-box-icon {
  font-size: 60px;
  color: var(--on-surface);
  margin-bottom: 20px;
}

.no-reports-suggestion {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--on-surface-low);
  margin-top: 10px;
}

.no-reports-lightbulb-icon {
  font-size: 24px;
  color: #f39c12;
  margin-right: 10px;
}

.no-reports-chart-icon {
  font-size: 24px;
  color: #02d51b;
  margin-right: 10px;
}

.no-reports-suggestion p {
  margin: 0;
}

.blur-content {
  filter: blur(10px);
  user-select: none;
  pointer-events: none;
}
.new-report-ribbon {
  position: absolute;
  top: 0px;
  right: 0px;
  background: linear-gradient(45deg, #34eb4f, #0d6f0d);
  color: var(--on-surface);
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  animation: ribbon-text-animation 2s infinite;
}

@keyframes ribbon-text-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .Research_and_marketNews_container {
    font-family: "Arial", sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }

  .report-container {
    flex: 1 1 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 22px;
    margin-bottom: 20px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border: 2px solid green;
  }

  .Research_report_title {
    font-size: 20px;
    margin-bottom: 5px;
  }

  .report-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .reportView_companyName {
    font-size: 16px;
    margin: 0;
  }

  .price-info {
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    gap: 5px;
    padding: 7px;
    border-radius: 25px;
  }

  .report_price h4,
  .report_price_percentage p {
    font-size: 12px;
    margin: 0;
  }

  .status-rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
  }

  .shariah-status {
    font-size: 14px;
    margin-top: 0;
    text-align: left;
    background-color: none;
  }

  .research-star-rating {
    flex-shrink: 0;
    display: flex;
    align-items: center;
  }

  .research-star-rating .star {
    font-size: 20px;
    margin-top: 10px;
  }

  .report-date_researchView {
    font-size: 11px;
  }

  .reasearc_view_Readmore_text_div {
    margin-left: 0px;
    margin-top: 0;
  }

  .research_view_readmoretext {
    font-size: 13px;
    margin: 0;
  }
}
