.adminpanel__container {
  display: flex;
  min-height: 100vh;
  background: linear-gradient(135deg, #2b2b2b, #1f1f1f, #0d0d0d);
  margin-top: 0rem;
  background-size: 400% 400%;
  animation: gradientAnimation 20s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.adminpanel__sidebar {
  width: 250px;
  background: linear-gradient(135deg, #2b2b2b, #1f1f1f, #0d0d0d);
  padding: 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s ease-in-out;
}

.adminpanel__sidebar.collapsed {
  width: 80px;
  
}

.adminpanel__main {
  flex: 1;
  background: linear-gradient(135deg, #1e1e1e, #121212, #0a0a0a); /* Perfect dark color gradient */
  color: #ffffff; /* White text for contrast */
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Slightly darker shadow for a more subtle effect */
  border-radius: 8px; /* Slightly increased border-radius for smoother corners */
}


.adminpanel__button {
  width: 100%;
  padding: 1rem;
  text-align: left;
  color: #ccc;
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  border-radius: 7px;
}

.adminpanel__button:hover {
  background-color: #444;
}

.adminpanel__button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.5);
}

.adminpanel__button.active {
  background-color: #444;
  color: #fff;
}

.adminpanel__sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.adminpanel__sidebar-header h2 {
  font-size: 1.25rem;
  font-weight: bold;
  color: #ccc;
}

.adminpanel__sidebar-header .toggle-button {
  background-color: transparent;
  border: none;
  color: #ccc;
  cursor: pointer;
  font-size: 1.25rem;
  transition: color 0.3s ease-in-out;
}

.adminpanel__sidebar-header .toggle-button:hover {
  color: #fff;
}

.hidden {
  display: none;
}

.inline {
  display: inline;
}

/* Media Queries for Mobile View */
@media (max-width: 768px) {
  .adminpanel__container {
    flex-direction: flex;
    min-height: 100vh;
    background: linear-gradient(135deg, #2b2b2b, #1f1f1f, #0d0d0d);
    margin-top: 4rem;
  }

  .adminpanel__sidebar {
    width: 80px; /* Collapsed width showing only icons */
    background: linear-gradient(135deg, #2b2b2b, #1f1f1f, #0d0d0d);
    padding: 1rem;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 10;
  }

  .adminpanel__sidebar-header h2 {
    display: none; /* Hide the text in the header */
  }

  .adminpanel__sidebar-header .toggle-button {
    font-size: 1.25rem;
    color: #ccc;
    margin: 0 auto; /* Center the toggle button */
  }

  .adminpanel__main {
    margin-left: 80px; /* Adjusted margin to account for collapsed sidebar */
    width: calc(100% - 80px); /* Adjust width to account for sidebar */
    padding: 1rem;
    background: linear-gradient(135deg, #1e1e1e, #121212, #0a0a0a); /* Dark gradient */
    border-radius: 0; /* No border-radius for full-width effect */
    overflow-y: auto;
    flex: 1;
  }

  .adminpanel__button {
    justify-content: center; /* Center the icons */
    padding: 0.75rem;
    font-size: 1rem;
    font-weight: 600;
    color: #ccc;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    border-radius: 7px;
    width: 100%; /* Full width for button area */
  }

  .adminpanel__button span {
    display: none; /* Hide text labels in mobile view */
  }

  .adminpanel__button:hover {
    background-color: #444;
  }

  .adminpanel__button.active {
    background-color: #444;
    color: #fff;
  }

  .hidden {
    display: none;
  }

  .inline {
    display: inline;
  }
}
