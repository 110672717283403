.box {
  display: flex;
  position: relative;
}
.box--row {
  flex-direction: row;
}
.box--col {
  flex-direction: column;
}

@media (max-width: 768px) {
  .box {
    flex-direction: column; /* Ensure content stacks vertically on smaller screens */
    gap: 8px; /* Reduce gap for better spacing on mobile */
    padding: 16px; /* Add default padding for better layout */
  }

  .box--row {
    flex-direction: column; /* Override row layout to behave like a column on mobile */
  }

  .box--col {
    flex-direction: column; /* Ensure column remains column */
  }
}
