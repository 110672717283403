.tradingview-widget-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 600px;
}

#tradingview_0afdf {
  width: 100%;
  height: 100%;
}

.tradingview-widget-copyright {
  margin: 0px;
}

.tradingview-widget-copyright .blue-text {
  color: #0D47A1;
}