.companydetails-container {
  padding: 1rem;
  background-color: var(--surface);
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.fab-container {
  position: fixed;
  z-index: 1000;
  bottom: 30px;
  right: 30px;
}

.fab-button {
  background: linear-gradient(to right, #00df59, #00a0c9);
  border: none;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 24px;
  border-radius: 50% solid;
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 20px;
  right: 40px;

  z-index: 100;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition-duration: 0.4s;
}

.fab-button:hover {
  background-color: #45a049;
}

.autocomplete-container {
  position: absolute;
  top: -40px;
  width: 250px;
}

.searchIcon_companydetails_flatingsearch {
  margin-bottom: 50px;
}

@keyframes searchScan {
  0%,
  100% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(10px);
  }
}

.searchIcon_companydetails_flatingsearch {
  display: inline-block;
  animation: searchScan 2s infinite ease-in-out;
}

.company-header {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--surface);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.CompanyDetailsName {
  font-size: 2rem;
  color: #000000;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  letter-spacing: 1.5px;
  padding: 5px;
}

.sector {
  font-size: 16px;
  color: #00a000;
  background-color: #e8fee8;
  border: 2px solid #00a000;
  border-radius: 4px;
  font-style: italic;
  padding: 5px 10px;
  margin-top: 2px;
}

.compnydetails_stockprice_container {
  margin: 0px;
  margin-left: 20px;
  padding: 0px;
  width: 300px;
}

.company-details-stock-price .stock-price {
  margin-top: 0px;
  padding: 0px;
}

.company-details-stock-price .price-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 5px;
  width: 100%;
  margin: 0px;
}

.company-details-stock-price .price__currentamount {
  font-size: 25px;
  padding: 5px;
  margin: 0px;
}

.company-details-stock-price .Current__priceChnage {
  margin-top: 15px;
  margin-left: 5px;
}

.company-details-stock-price .Current__priceChnage .pricechange__span {
  padding: 10px;
}

.company-details-stock-price .last_uppdated_date {
  font-size: 10px;
  padding: 5px;
  color: rgb(109, 109, 109);
}

.symboll-conatiner {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  background-color: #e8fee8;
  border: 2px solid #00a000;
  color: #000000;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: inline-block;
  transition: background-color 0.3s;
}

.symboll-conatiner:hover {
  background-color: #555;
  cursor: pointer;
}

.watchlist-button-container {
  position: absolute;
  top: 10px;
  right: 20px;
}

.watchlist-button {
  background-color: var(--primary);
  color: var(--on-primary);
  border-radius: 25px;
  padding: 8px 16px;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.watchlist-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.watchlist-button:disabled {
  background: var(--surface);
  cursor: not-allowed;
}

.success-header_watchlist {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
  padding: 1rem 2rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: black;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.success-message_watchlist {
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  margin: 0;
  color: #000000;
  text-align: center;
}

.watchlist_parah {
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
  margin: 0;
  color: #000000;
  text-align: center;
}

.Watchlist_ok-button {
  margin-top: 10px;
  padding: 5px 10px;
  background-color: #009688;
  color: white;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s, transform 0.2s;
  font-weight: 600;
  cursor: pointer;
}

.Watchlist_ok-button:hover {
  background-color: #04c434;
  transform: scale(1.05);
}

.login-required-header {
  font-size: 1.3rem;
  font-weight: 600;
  margin: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--on-surface);
}

.material-icons {
  font-size: 1.5rem;
}

.login-required-content {
  padding: 20px;
  text-align: center;
}

.login-required-message {
  font-size: 18px;

  margin: 20px 0;
}

.login-required-ok-button {
  padding: 6px 20px;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 4px;
  transition: background-color 0.3s;
  backgound-color: var(--surface);
}

.login-required-ok-button:hover {
  background-color: #0056b3;
}

.tradingview_chart_container {
  padding: 10px;
  margin: 0px;
  background-color: var(--surface);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sector-container {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.companydetails_business_model_header {
  padding: 10px;
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.comanydetail__orderlist_main_conatiner {
  width: 100%;
  max-width: 1400px;
  margin-left: 20px;
}

.companydetails_businessmodel__business_list {
  width: 100%;
  max-width: auto;
}

.companydetails__businessmodel-point {
  font-size: 16px;
  text-align: left;
  padding: 8px;
  border: 2px solid #00a000;
  width: auto;
  border-radius: 7px;
  font-weight: 500;
  display: inline-block;
}

.companydetails_investment_prospective__container {
  background-color: var(--surface);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.companydetails__prospective-title {
  font-size: 1.5rem;
  color: --on-surface;
  font-weight: bold;
  text-align: left;
  padding: 10px;

  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 0px;
}

.companydetails_investment_prospective__parah {
  color: --on-surface;
  font-size: 16px;
  text-align: left;
  padding: 10px;
  line-height: 1.7;
  margin-top: 0px;
  margin-left: 20px;
  font-weight: 500;
}

.Business-three {
  font-size: 1.5rem;
  color: #666;
  text-align: center;
}

.companydetails_note {
  border: 2px solid #00a000;
  padding: 5px;
  border-radius: 8px;
  text-align: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.companydetails_note p {
  color: grey;
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  padding: 2px;
}

.large-icon-up {
  width: 32px;
  height: 32px;
}

.shariahstatus_rating_compnydetails__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 10px;
  background-color: var(--surface);
  color: var(--on-surface);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.shariah__status-card,
.companydetails__rating-container {
  width: 45%;
  box-sizing: border-box;
}

.shariah__status-card {
  text-align: center;
  border-radius: 7px;
  padding: 5px 25px;
  margin-bottom: 0;
}

.companydetails__rating-container {
  text-align: center;
  border-radius: 10px;
}

.shariah-status_title {
  font-size: 1.5rem;
  color: var(--on-surface);
  font-weight: bold;
  padding: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
}

.status-boxes {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  margin-top: 0px;
}

.status-box {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}

.status-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.final-status {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  padding: 5px;
  display: inline-block;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.halal-status {
  color: #06d001;
  border: 1px solid #06d001;
}

.non-halal-status {
  color: orange;
  border: 1px solid orange;
}

.companydetails_details_shariahbutton_container {
  width: 100%;
  display: block;
  padding: 10px;
}

.companydetails_rating__title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 10px;
  border-radius: 15px;
  margin: 10px;
  display: inline-block;
  margin-top: 30px;
}

.compnydetails_stars_div {
  margin-top: 40px;
  padding: 10px;
}

.stars_classname .MuiSvgIcon-root {
  font-size: 50px;
}

.companydetails_details_shariahbutton_container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.Shariah_details_butoon_companydetais {
  background-color: var(--primary);
  border: none;
  color: var(--on-primary);
  padding: 7px 10px;
  border-radius: 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.Shariah_details_butoon_companydetais:hover {
  background-color: var(--primary);
}

.Shariah_details_butoon_companydetais span {
  margin-right: 10px;
}

.arrow-icon {
  vertical-align: middle;
}

.shariahdetails_button_doughnuticon {
  color: yellow !important;
  font-size: 20px;
  margin: 2px;
}

.shariahdetails_button_doughnuticon:hover {
  color: yellow !important;
}

.shariahdetails_button_barcharticon {
  color: yellow !important;
}

.shariahdetails_button_barcharticon:hover {
  color: yellow !important;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.shariahdetails_button_doughnuticon {
  display: inline-block;
  animation: spin 4s linear infinite;
}

@keyframes stretch {
  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(1.2);
  }
}

.shariahdetails_button_barcharticon {
  display: inline-block;
  animation: stretch 2s ease-in-out infinite;
}

@keyframes clickEffect {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(2px);
  }
}

.Shariah_details_butoon_companydetais {
  display: inline-block;
  animation: clickEffect 2s infinite;
}

.companydetails__Fundamenatal-data {
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: var(--surface);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.Fundametal_data-title {
  font-size: 1.5rem;
  color: var(--on-surface);
  font-weight: bold;
  padding: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 0;
  text-align: left;
}

.FinancialCardsGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 5px;
}

.Financialcard {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  width: calc((100% / 3) - 100px);
  min-width: 350px;
  margin: 5px;
}

.CompanyDetails {
  text-align: center;
}

.CompanyDetails-Fundamental_data__title {
  font-size: 1.2rem;
  color: var(--on-surface);
  padding: 5px;
  position: relative;
  overflow: hidden;
  font-weight: 700;
}

.CompanyDetails-ddd {
  font-size: 1rem;
  color: var(--on-surface-variant);
  font-weight: 700;
  padding: 5px;
  margin: 5px;
  display: inline-block;
}

.compnydetails_button_up_back_container {
  margin: 15px;
  position: relative;
}

.companydetails_goBack__button {
  display: inline-block;
  background-color: var(--primary);
  color: var(--on-primary);
  cursor: pointer;
  text-align: center;
  justify-content: center;
  width: 150px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.companydetails_goBack__button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.companydetails_goBack__button:active {
  transform: translateY(1px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.scroll-top-button {
  display: inline-block;
  background-color: var(--primary);
  color: var(--on-primary);
  cursor: pointer;
  text-align: center;
  justify-content: center;
  width: 50px;
  padding: 10px;
  border-radius: 30px;
  margin-top: 10px;
  margin-bottom: 20px;
  transition: transform 0.2s, box-shadow 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  position: absolute;
  right: 30px;
}

.gototop_button_icon {
  text-align: center;
  font-size: 30px !important;
  color: white !important;
}

.scroll-top-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
}

.scroll-top-button:active {
  transform: translateY(2px);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
  .companydetails-container {
    padding: 0.5rem;
    background-color: var(--surface);
  }

  .company-header {
   display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--surface);
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  }

  .CompanyDetailsName {
    font-size: 1.5rem;
    padding: 3px;
  }

  .sector {
    font-size: 14px;
    padding: 3px 7px;
    margin-top: 5px;
  }

  .compnydetails_stockprice_container {
    margin: 0px;
    margin-left: 20px;
    padding: 0px;
    width: 100%;
    max-width: 300px;
    border-radius: 15px;
  }

  .company-details-stock-price .stock-price {
    margin-top: 0px;
    padding: 0px;
  }

  .company-details-stock-price {
    width: 80% !important;
  }

  .company-details-stock-price .price-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    margin: 0px;
    color: black;
  }

  .company-details-stock-price .price__currentamount {
    font-size: 20px;
    padding: 4px;
    margin: 0px;
    color: black;
    font-weight: 600px;
  }

  .company-details-stock-price .Current__priceChnage {
    margin-top: 10px;
    margin-left: 2px;
  }

  .company-details-stock-price .Current__priceChnage .pricechange__span {
    padding: 8px;
  }

  .company-details-stock-price .last_uppdated_date {
    font-size: 10px;
    padding: 5px;
    color: rgb(109, 109, 109);
  }

  .symboll-conatiner {
    font-size: 14px;
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 15px;
    margin-top: 10px;
  }

  .sector-container {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }

  .companydetails_business_model_header {
    padding: 10px;

    font-size: 1.2rem;

    color: #000;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    text-align: left;
  }

  .comanydetail__orderlist_main_conatiner {
    width: 100%;
    margin-left: 8px;
  }

  .companydetails_businessmodel__business_list {
    width: 100%;
  }

  .companydetails__businessmodel-point {
    font-size: 14px;
    text-align: left;
    padding: 6px;
    border: 2px solid #00a000;
    border-radius: 7px;
    font-weight: 500;
    display: inline-block;
    color: black;
  }

  .companydetails_investment_prospective__container {
    background-color: var(--surface);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .companydetails__prospective-title {
    font-size: 1.2rem;
    color: var(--on-surface);
    font-weight: bold;
    text-align: left;
    padding: 10px;

    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
    margin-bottom: 0px;
  }

  .companydetails_investment_prospective__parah {
    color: var(--on-surface);
    font-size: 14px;
    text-align: left;
    padding: 10px;
    line-height: 1.7;
    margin-top: 0px;
    margin-left: 20px;
    font-weight: 500;
  }

  .Business-three {
    font-size: 1.2rem;
    color: #666;
    text-align: center;
  }

  .companydetails_note {
    background-color: #e8fee8;
    border: 2px solid #00a000;
    padding: 5px;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .companydetails_note p {
    color: #018125;
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    padding: 2px;
  }

  .large-icon-up {
    width: 24px;
    height: 24px;
  }

  .shariahstatus_rating_compnydetails__container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .shariah__status-card {
    border-radius: 5px;
    padding: 5px 18px;
    border: 1px solid black;
    margin-bottom: 5px;
  }

  .shariah-status_title {
    font-size: 1.2rem;
    color: var(--on-surface);
    padding: 8px;
  }

  .status-boxes {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 0px;
  }

  .status-box {
    width: 100px;
    height: 100px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .final-status {
    font-size: 1rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .shariah__status-card,
  .companydetails__rating-container {
    width: 48%;
    box-sizing: border-box;
    border: 1px solid black;
  }

  .companydetails_rating__title {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    padding: 8px;
    border-radius: 15px;
    margin: 5px;
  }

  .compnydetails_stars_div {
    margin-top: 20px;
    padding: 20px;
  }

  .stars_classname .MuiSvgIcon-root {
    font-size: 50px !important;
  }

  .companydetails_details_shariahbutton_container {
    width: 100%;
    order: 3;
    display: flex;
    justify-content: flex-end;
  }

  .Shariah_details_butoon_companydetais {
    background-color: var(--primary);
    border: none;
    color: var(--on-primary);
    padding: 8px 10px;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: right;
  }

  .shariahdetails_button_doughnuticon {
    color: yellow !important;
    font-size: 20px !important;
    margin: 2px;
  }

  .shariahdetails_button_barcharticon {
    color: yellow !important;
    font-size: 20px !important;
  }

  .FinancialCardsGrid {
    justify-content: space-around;
  }

  .Financialcard {
    width: calc(50% - 10px);
    margin: 5px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    min-width: 0;
  }

  .Fundametal_data-title {
    font-size: 1.2rem;
    padding: 8px;
  }

  .CompanyDetails-Fundamental_data__title {
    font-size: 1rem;
    padding: 4px;
    font-weight: 700;
    border-bottom: none;
  }

  .CompanyDetails-Fundamental_data__title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.99px;
    background-image: none;
    background-size: 200% 100%;
    animation: slide 3s linear infinite;
  }

  .CompanyDetails-ddd {
    font-size: 0.8rem;
    color: var(--on-surface-variant);
    font-weight: 600;
    margin: 4px;
    border: none;
  }

  .watchlist-button-container {
    top: 140px;
    right: 10px;
  }

  .watchlist-button {
    padding: 10px 10px;
    font-size: 14px;
  }

  .success-header_watchlist {
    font-size: 1rem;
    padding: 0.8rem 1.5rem;
  }

  .success-message_watchlist {
    font-size: 1.2rem;
    padding: 1rem 1.5rem;
  }

  .Watchlist_ok-button {
    padding: 0.5rem 0.8rem;
    font-size: 1rem;
  }
}

@media (min-width: 320px) and (max-width: 460px) {
  .company-header {
    padding-bottom: 100px;
  }

  .sector {
    font-size: 12px;
    padding: 3px 5px;
    margin-top: 5px;
  }

  .compnydetails_stockprice_container {
    margin: 0px;
    margin-left: 0px;
    padding: 0px;
    width: 100%;
  }

  .company-details-stock-price {
    width: 100%;
  }

  .company-details-stock-price .price-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 5px;
    margin: 0px;
    color: black;
    width: 100%;
  }

  .company-details-stock-price .price__currentamount {
    font-size: 18px;
  }

  .symboll-conatiner {
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    padding: 8px 12px;
    margin-top: 8px;
  }

  .sector-container {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 8px;
  }

  .companydetails_business_model_header {
    padding: 8px;
    font-size: 1rem;
  }

  .comanydetail__orderlist_main_conatiner {
    width: 100%;
    margin-left: 5px;
  }

  .companydetails__businessmodel-point {
    font-size: 12px;
    text-align: left;
    padding: 5px;
  }

  .companydetails_investment_prospective__container {
    background-color: var(--surface);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    margin-top: 3px;
    margin-bottom: 3px;
  }

  .companydetails__prospective-title {
    font-size: 1rem;
    padding: 8px;
  }

  .companydetails_investment_prospective__parah {
    font-size: 12px;
    text-align: left;
    padding: 8px;
    line-height: 1.5;
    margin-top: 0px;
    margin-left: 0px;
    font-weight: 500;
  }

  .companydetails_note {
    background-color: #e8fee8;
    border: 2px solid #00a000;
    padding: 5px;
    border-radius: 8px;
    text-align: left;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  .shariahstatus_rating_compnydetails__container {
    flex-direction: column;
    width: 100%;
  }

  .shariah__status-card,
  .companydetails__rating-container {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid black;
  }

  .shariah__status-card {
    border-radius: 5px;
    padding: 5px 14px;
    border: 1px solid black;
    margin-bottom: 5px;
  }

  .shariah-status_title {
    font-size: 1rem;
    color: var(--on-surface);
    padding: 5px;
  }

  .status-boxes {
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 0px;
  }

  .status-box {
    width: 80px;
    height: 80px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .final-status {
    font-size: 0.9rem;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .companydetails_rating__title {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding: 8px;
    border-radius: 15px;
    margin: 5px;
  }

  .compnydetails_stars_div {
    margin-top: 20px;
    padding: 10px;
  }

  .stars_classname .MuiSvgIcon-root {
    font-size: 30px !important;
  }

  .Fundametal_data-title {
    font-size: 1rem;
    padding: 5px;
  }

  .companydetails_note p {
    font-size: 12px;
    line-height: 1.3;
    margin: 0;
    font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
      "Lucida Sans", Arial, sans-serif;
    padding: 2px;
  }

  .watchlist-button-container {
    position: absolute;
    top: 75%;
    right: 10px;
    bottom: auto;
    width: 90%;
  }

  .watchlist-button:disabled {
    background: #9e9e9e;
    cursor: not-allowed;
    padding: 10px;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .company-header {
    padding: 10px 10px 50px 10px;
  }

  .symboll-conatiner {
    position: relative;
  }

  .watchlist-button-container {
    position: absolute;
    top: 81%;
    right: 20px;
    bottom: auto;
  }
}

@media (min-width: 425px) and (max-width: 460px) {
  .company-header {
    padding: 10px 10px 50px 10px;
  }

  .symboll-conatiner {
    position: relative;
  }

  .watchlist-button-container {
    position: absolute;
    top: 80%;
    left: 20px;
    bottom: auto;
    padding: 2px;
  }
}
