.accordion{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.accordion__item{
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    transition: all;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    cursor: pointer;
}
.accordion__item--color:hover{
    background-color: var(--surface-container-low);
}
.accordion__item--selected{
    background-color: var(--surface-container-low);
}
.accordion__header{
    display: flex;
    align-items: center;
    gap: 8px;
}
.accordion__radio{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--on-surface-low);
}
.accordion__radio--selected{
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background-color: var(--primary-variant);
    transition: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}
.accordion__content{
    display: flex;
    height: fit-content;
    margin-top: 0px;
    max-height: 0%;
    transition: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    overflow: hidden;
    opacity: 0;
}
.accordion__content--expanded{
    margin-top: 12px;
    max-height: 100%;
    opacity: 1;
}
.accordion__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}
.accordion__icon--rot{
    transform: rotate(180deg);
}