.hero-search{
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.hero-search__bar{
    display: flex;
    padding: 4px;
    border-radius: 26px;
    background: linear-gradient(to right, var(--surface-container), var(--surface-container-highest));
}
.hero-search__search-icon{
    display: flex;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: var(--on-surface-low);
    font-family: "Material Symbols Rounded";
    font-size: 24px;
    font-variation-settings:
    'FILL' 0, 
    'wght' 400, 
    'GRAD' 0, 
    'opsz' 24;
}
.hero-search__input{
    display: flex;
    width: 100%;
    background-color: transparent;
}
.hero-search__input--font{
    font-family: "Funnel Sans";
    font-size: 20px;
    font-weight: 400;
    color: var(--on-surface-variant);
}
.hero-search__input--font::placeholder{
    font-family: "Funnel Sans";
    font-size: 20px;
    font-weight: 400;
    color: var(--on-surface-low);
}
.hero-search__input:focus{
    outline: none;
}
.hero-search__button{
    display: flex;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    color: var(--on-surface-inverse);
    font-family: "Material Symbols Rounded";
    font-size: 24px;
    font-variation-settings:
    'FILL' 0, 
    'wght' 400, 
    'GRAD' 0, 
    'opsz' 24;
    background-color: var(--surface-inverse);
    border-radius: 24px;
}