.screener-page-container {
  padding: 8rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--surface);
}

.Screener-title-text {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 600;
  color: var(--on-surface);
  padding: 5px !important;
}

.search-br {
  display: flex;
  justify-content: center;
  margin-top: 0px;
}

.Screener_table_container {
  border: 1px solid black;
  border-radius: 7px;
  padding: 2px;
  background-color: var(--surface-container-high);
}

.htwoscreenr {
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--on-surface-variant);
  text-transform: uppercase;
  letter-spacing: 1px;
  border-bottom: 1px solid #66ec0d;
  padding-bottom: 7px;
  text-align: center;
  background-color: var(--surface-container-low);
  padding: 10px;
  margin-bottom: 0px;
}

.data-item .Screener_companyname_table {
  font-size: 15px;
  width: 100px;
  padding: 7px;
  text-decoration: none;
  color: var(--on-surface);
  font-weight: 600;
}

.table-wrapper {
  overflow-y: auto;
}

.table-wrapper .Screener__page_table thead th {
  position: sticky;
  padding: 10px;
  background-color: var(--surface-container-low);
  color: white;
  text-align: center !important;
}

.data-item .Screener_companyname_table {
  font-size: 15px;
  width: 100px;
  padding: 7px;
  text-decoration: none;
  color: #120c2c;
  font-weight: 600;
  text-align: center;
  color: var(--on-surface);
}

.data-item_businessPermisibility_status {
  width: 200px;
  text-align: center;
}

.data-item_interest_torevenue {
  width: 180px;
  text-align: center;
  font-weight: 600;
  color: var(--on-surface);
}

.data-item_laon_toMarket {
  width: 180px;
  text-align: center;
  font-weight: 600;
  color: var(--on-surface);
}

.data-item_investment_marketcap {
  width: 250px;
  text-align: center;
  font-weight: 600;
  color: var(--on-surface);
}

.data-item_final_status {
  width: 150px;
  text-align: center;
}

.tss-1v1f2er-MUIDataTablePagination-root .MuiToolbar-root {
  justify-content: center !important;
  flex-wrap: wrap;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  padding: 0px !important;
}

.css-nfmglb-MuiTableCell-root {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 5px !important;
  color: rgba(0, 0, 0, 0.6);
  display: flex !important;
  justify-content: flex-end !important;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
  margin: 0px !important;
  border-radius: 4px;
  padding: 0.5rem 2rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.08) !important;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  padding: 2px !important;
  background-color: var(--surface-container-low);
  color: var(--on-surface);
}

.tss-1v1f2er-MUIDataTablePagination-root {
  width: 100%;
  display: flex;
  justify-content: center !important;
  border: 1px solid black !important;
  border-radius: 7px !important;
  background-color: var(--surface-container-low);
  color: var(--on-surface);
}

.MuiButtonBase-root.MuiIconButton-root {
  border: 2px solid rgb(6, 177, 244) !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  border-radius: 25px !important;
  width: 40px !important;
  background-color: var(--surface-container-low) !important;
  font-size: 10px !important;
}

.MuiButtonBase-root.MuiIconButton-root svg {
  color: rgb(255, 255, 255) !important;
  fill: currentColor;
  font-size: 20px;
  background-color: var(--surface-container-low);
  color: var(--on-surface) !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500 !important;
  font-size: 1rem !important;
  padding: 10px !important;
  padding-top: 10px !important;
  margin-top: 15px !important;
  text-align: center !important;
  background-color: var(--surface-container-low);
}

.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-right: 10px !important;
  margin-left: 10px;
  background-color: var(--surface-container-low);
}

.css-pdct74-MuiTablePagination-selectLabel {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 1rem !important;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 10px !important;
  margin-top: 15px !important;
  background-color: var(--surface-container-low);
}

.Screener_tbales_list {
  font-size: 1.8rem;
  font-weight: bold;
  color: #000000;
  margin-bottom: 10px;
  display: block;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--surface-container-low);
}

.MuiTableCell-footer {
  background-color: var(--surface-container-low) !important;
  color: var(--on-surface) !important;
}

.MuiTableCell-footer {
  background-color: var(--surface-container-low) !important;
  color: var(--on-surface) !important;
}

/* this is the text colo */
.MuiIconButton-colorInherit {
  color: var(--on-surface) !important;
}

@media (max-width: 768px) {
  .screener-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .Screener-title {
    font-size: 1.4rem;
    padding: 10px;
  }

  .table-wrapper .Screener__page_table thead th {
    font-size: 0.9rem;
    padding: 0px;
  }

  .Screener__page_table th {
    font-size: 0.9rem;
  }

  .Screener_tbales_list {
    font-size: 0.8rem;
  }

  .profit-growth {
    font-size: 0.8rem;
  }

  .sales-growth {
    font-size: 0.8rem;
  }

  .price-to-book h2 {
    font-size: 0.8rem;
  }

  .price-to-earning h2 {
    font-size: 0.8rem;
  }

  .market-capitalisation h2 {
    font-size: 0.8rem;
  }

  .Company_name_Screener {
    font-size: 0.8rem;
  }

  .htwoscreenr {
    font-size: 0.9rem;
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .tss-1v1f2er-MUIDataTablePagination-root .MuiToolbar-root {
    justify-content: start !important;
    flex-direction: row !important;
    margin-top: 1px !important;
    margin-bottom: 1px !important;
    padding: 0.5rem !important;
  }

  .css-nfmglb-MuiTableCell-root {
    font-size: 0.65rem !important;
    padding: 2px !important;
    justify-content: center !important;
  }

  .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
    padding: 0.25rem !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  }

  .MuiButtonBase-root.MuiIconButton-root {
    border-radius: 10px !important;

    width: 90px !important;

    font-size: 9px !important;
  }

  .MuiButtonBase-root.MuiIconButton-root svg {
    font-size: 18px;
  }

  .css-levciy-MuiTablePagination-displayedRows,
  .css-pdct74-MuiTablePagination-selectLabel {
    font-size: 0.9rem !important;
    padding: 2px !important;
    text-align: center !important;
  }

  .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
    margin-right: 8px !important;
    margin-left: 8px !important;
  }
}
