.researchreport__main-container {
  animation: fadeIn 0.6s ease-out;
  font-family: "Roboto", sans-serif;
  background-color: var(--surface);
  padding: 4rem;
  height: auto;
}

.research-detail__company-container {
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .researchreport__main-container {
    animation: fadeIn 0.6s ease-out;
    font-family: "Roboto", sans-serif;
    background-color: var(--surface);
    padding: 10px;
  }
}
