.navbar {
  display: flex;
  position: relative;
  position: sticky;
  align-items: center;
  top: 0px;
  width: 100%;
  height: 64px;
  padding: 0px 64px;
  background-color: var(--surface-container-low);
  z-index: 50;
}
.navbar__logo-text {
  font-family: "Funnel Display", "Sans Serif";
  font-size: 24px;
  font-weight: 700;
  color: var(--on-surface);
}
.nav-item {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.nav-item:hover .nav-item__radio--hovered {
  width: 6px;
  height: 6px;
}
.nav-item__radio {
  display: block;
  bottom: 0px;
  width: 0px;
  height: 0px;
  border-radius: 4px;
  background-color: var(--on-surface-low);
  transition: all 100ms ease-in-out;
}

.nav-item__radio--selected {
  width: 6px;
  height: 6px;
  background-color: var(--primary-variant);
}
.navbar__dropdown {
  display: flex;
  position: absolute;
  top: 0px;
  right: 0px;
  flex-direction: column;
  padding: 72px 64px 24px 24px;
}
.dropdown__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 4px;
  border-radius: 16px;
  background-color: var(--surface-container);
}
.dropdown__button {
  display: flex;
  gap: 8px;
  height: 40px;
  align-items: center;
  width: 100%;
  padding-left: 12px;
  padding-right: 16px;
  border-radius: 14px;
  color: var(--on-surface-variant);
  transition: all 100ms ease-in-out;
}
.dropdown__button--neutral:hover {
  color: var(--on-primary-container);
  background-color: var(--primary-container);
}
.dropdown__button--error:hover {
  color: var(--on-error);
  background-color: var(--error);
}
.dropdown-button__icon {
  font-family: "Material Symbols Rounded";
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
}
.dropdown-button__label {
  font-family: "Funnel Sans";
  font-size: 18px;
  font-weight: var(--font-weight-label);
  cursor: pointer;
}

.side-drawer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: white;
  z-index: 1000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
}

.side-drawer__overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.side-drawer__content {
  padding: 20px;
}

.side-drawer__close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}

.side-drawer__item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.side-drawer__item:hover {
  background: #f5f5f5;
}
