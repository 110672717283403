.icon {
  display: block;
  font-family: "Material Symbols Rounded";
  color: var(--on-surface);
  overflow: hidden;
  flex-shrink: 0;
}
.icon--large {
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 40;
}
.icon--medium {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icon--small {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 20;
}

.icon--large-filled {
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 40;
}
.icon--medium-filled {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}
.icon--small-filled {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
}

.icon--color-inherit {
  color: inherit;
}
.icon--color-primary {
  color: var(--primary-variant);
}
.icon--color-on-primary {
  color: var(--on-primary);
}
.icon--color-inverse {
  color: var(--on-surface-inverse);
}
.icon--color-default {
  color: var(--on-surface);
}
.icon--color-variant {
  color: var(--on-surface-variant);
}
.icon--color-low {
  color: var(--on-surface-low);
}

@media (max-width: 768px) {
  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Center horizontally */
  }

  .icon--large,
  .icon--medium,
  .icon--small {
    margin: 0 auto; /* Center icon itself if additional padding is present */
  }
}

/* Filled variants */
.icon--large-filled {
  width: 40px;
  height: 40px;
  font-size: 40px;
  line-height: 40px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 40;
}

.icon--medium-filled {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

.icon--small-filled {
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 20;
}

/* Color variants */
.icon--color-inherit {
  color: inherit;
}

.icon--color-primary {
  color: var(--primary-variant);
}

.icon--color-on-primary {
  color: var(--on-primary);
}

.icon--color-inverse {
  color: var(--on-surface-inverse);
}

.icon--color-default {
  color: var(--on-surface);
}

.icon--color-variant {
  color: var(--on-surface-variant);
}

.icon--color-low {
  color: var(--on-surface-low);
}
