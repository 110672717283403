@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';
@font-face {
  font-family: 'Material Icons';
  src: url('https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
  font-display: swap;
}
@import 'tailwindcss/tailwind.css';









