@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.unsubscribe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  animation: fadeIn 1s ease-in-out;
}

.unsubscribe-box {
  padding: 40px 60px;
  border-radius: 10px;
  text-align: center;
  max-width: 800px;
  width: 100%;
  animation: popUp 0.5s ease-in-out;
}

.unsubscibe__title {
  color: #333;
  margin-bottom: 20px;
  margin-top:20px;
  font-weight: bold;
}

.unsubscribe-box p {
  color: #666;
  font-size: 18px;
  margin-top: 10px;
}

.icon-green {
  color: #28a745;
}

.icon-red {
  color: #dc3545;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes popUp {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

.home-button {
  background: linear-gradient(45deg, #28a745, #1c7430);
  color: #fff;
  border: none;
  padding: 2px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s ease;
}

.home-button:hover {
  background: linear-gradient(45deg, #1c7430, #155d27);
}
