.justify-content-start{
    justify-content: start;
}
.justify-content-end{
    justify-content: end;
}
.justify-content-center{
    justify-content: center;
}
.justify-content-between{
    justify-content: space-between;
}
.justify-content-around{
    justify-content: space-around;
}
.justify-content-evenly{
    justify-content: space-evenly;
}
.justify-content-stretch{
    justify-content: stretch;
}

.align-items-start{
    align-items: start;
}
.align-items-end{
    align-items: end;
}
.align-items-center{
    align-items: center;
}
.align-items-stretch{
    align-items: stretch;
}