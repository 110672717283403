.width-full {
  width: 100%;
  maxwidth: 100%;
}
.width-fit {
  width: fit-content;
}
.width-1-col {
  width: 80px;
  flex-shrink: 0;
}
.width-2-col {
  width: 176px;
  flex-shrink: 0;
}
.width-3-col {
  width: 272px;
  flex-shrink: 0;
}
.width-4-col {
  width: 368px;
  flex-shrink: 0;
}
.width-5-col {
  width: 464px;
  flex-shrink: 0;
}
.width-6-col {
  width: 560px;
  flex-shrink: 0;
}
.width-7-col {
  width: 656px;
  flex-shrink: 0;
}
.width-8-col {
  width: 752px;
  flex-shrink: 0;
}
.width-9-col {
  width: 848px;
  flex-shrink: 0;
}
.width-10-col {
  width: 944px;
  flex-shrink: 0;
}
.width-11-col {
  width: 1040px;
  flex-shrink: 0;
}
.width-12-col {
  width: 1036px;
  flex-shrink: 0;
}
