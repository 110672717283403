.userAccount-page__container {
  padding: 6rem 2rem 7rem;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
}


.profile_image_container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  padding: 10px;
  display: inline-block;
  width:300px;
}

.userAccount-profile-details__container {
  display: flex;
  align-items: center;
  gap: 30px;

}

.Profile__image__user {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 3px solid #000000;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  color: #333;
 
}

.profile-placeholder {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  border: 3px solid #000000;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  font-size: 50px;
  font-weight: bold;
  color: #333;
}

.account-edit-overlay {
  position: absolute;
  bottom: 10%;
  left: 70%;
  transform: translateX(-50%);
  width: 100px;
  height: 30px;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.account-edit-overlay i {
  color: rgb(255, 255, 255);
  font-size: 15px;
  border: 1px solid black;
  padding: 5px;
  border-radius: 50%;
  background-color: rgb(255, 5, 5);
}

.account_userProfile-image-upload {
  display: none;
}

.userAccount-details__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  width: 100%;
}


.account-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.account-label {
  margin-bottom: 0.5rem;
}

.detail-text,
.input-container {
  padding: 0px
}

.input-container {
  display: flex;
  gap: 10px;
}

.input-field-a {
  flex: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-field-a {
  flex: 1;
  padding: 8px;
  border: 3px solid rgb(14, 209, 14);
  border-radius: 4px;
  background-color: rgb(212, 244, 255);
}


.input-field-a:focus {
  outline: none;
  border-color: darkgreen; 
}


.user_moredetails_container {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.details-left,
.details-right {
  display: flex;
  flex-direction: column;
  width: calc(50% - 1rem);
}

.account-detail {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.account-label {
  margin-bottom: 0.5rem;
}

.input-field-a,
.detail-text {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-field-a {
  width: 100%;
}

.subscription-status {
  font-weight: 600;
}

.subscription-status,
.subscription-status.inactive {
  color: green;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editing-btn-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.single-btn-group {
  display: flex;
  justify-content: center;  
  align-items: center;     
  width: 800px;             
  margin: 0 auto;   
  gap:20px; 
  padding:10px;   
}

.single-edit-btn {
  background: linear-gradient(to right, #a8e063, #56ab2f); 
  color: rgb(0, 0, 0);                   
  padding: 10px 35px;             
  border: 2px solid transparent;  
  cursor: pointer;                
  font-weight: bold;              
  border-radius: 30px;           
  text-align: center;             
  transition: all 0.3s ease;     
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
  width:300px;
}

.single-edit-btn:hover {
  background: linear-gradient(to right, #b4ec51, #429321);
  box-shadow: 0 4px 8px rgba(0,0,0,0.3); 
  transform: translateY(-2px);    
}

.single-edit-btn:active {
  background: linear-gradient(to right, #91c444, #307014); 
  box-shadow: 0 2px 4px rgba(0,0,0,0.2); 
  transform: translateY(1px);     
}



.btn {
  padding: 8px 35px;
  border: 2px solid transparent; 
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; 
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
}


.editing-save-btn {
  background: linear-gradient(to right, #56ab2f, #a8e063); 
  color: white;
}

.editing-save-btn:hover {
  background: linear-gradient(to right, #3e8e41, #a3cb38); 
  box-shadow: 0 4px 15px rgba(0,0,0,0.3); 
  transform: translateY(-2px); 
}

.editing-save-btn:active {
  background: linear-gradient(to right, #2e7031, #8ebf32);
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
  transform: translateY(1px); 
}

.editing-cancel-btn {
  background: linear-gradient(to right, #ff416c, #ff4b2b); 
  color: white;
}

.editing-cancel-btn:hover {
  background: linear-gradient(to right, #ff576e, #ff6342); 
  box-shadow: 0 4px 15px rgba(0,0,0,0.3); 
  transform: translateY(-2px); 
}

.editing-cancel-btn:active {
  background: linear-gradient(to right, #e0365a, #e04825); 
  box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
  transform: translateY(1px); 
}

.manage-subscription-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.single-manage-btn {
  background: linear-gradient(45deg, #2c3e50, #4ca1af); /* Gradient from dark to light */
  color: white;
}

.single-manage-btn:hover {
  background: linear-gradient(45deg, #1a252f, #3a7e8c); /* Darker gradient on hover */
  color:white;
}


.manage-subscription-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: linear-gradient(135deg, #000000, #434343);
  box-shadow: 24px;
  padding: 16px;
  border-radius: 15px;
  color: white; /* Ensure text is visible on dark background */
}

.cancelsubscription__modeltitle {
  font-weight: 600;
  color: white;
  margin-bottom: 5px;
  font-size:1.1rem
}

.manage-subscription-close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  color: #666;
  transition: color 0.3s ease;
  padding:5px;
}


/* src/components/YourComponent.css */

.save-button__updatedcomponenet {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 5px 30px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px; /* Added for spacing */
  align-self: flex-end; /* Aligns the button to the right */
}

.save-button__updatedcomponenet:hover {
  background-color: #45a049;
}

.manage-subscription-box {
  display: flex;
  flex-direction: column;
}

.button-container__modal_compoenent {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px; /* Space between content and button */
}


.emailnotification__maindiv {
  margin-top: 0px;
  background: linear-gradient(135deg, #1a1a1a, #333333); /* Dark gradient */
  padding: 10px;
  border-radius: 10px;
  color: white;
}

.emailnotification__main__titile{
  text-align: left;
    border-bottom: 1px solid white; /* Add underline */
    margin-bottom: 10px; /* Space below the title */
    font-size:1.1rem
}







@media (max-width: 768px) {
  .userAccount-page__container {
    padding: 5rem 0.5rem; 
    max-width: 100%; 
  }

  .profile_image_container {
    padding: 10px;
  }

  .Profile__image__user, .profile-placeholder {
    width: 200px; 
    height: 200px; 
  }

  .account-edit-overlay {
    bottom: 15%; 
    left: 60%; 
    width: 80px; 
    height: 25px; 
  }

  .userAccount-profile-details__container, .user_moredetails_container {
    flex-direction: column; 
    gap: 15px; 
  }

  .details-left, .details-right {
    width: 100%; 
  }

  .single-btn-group, .editing-btn-group {
    flex-direction: column;
    gap: 10px; 
    width:100%;
  }

  .single-edit-btn {
    background: linear-gradient(90deg, #375f3a, #4CAF50);
    color: rgb(0, 0, 0);                   
    padding: 10px 35px;             
    border: 2px solid transparent;  
    cursor: pointer;                
    font-weight: bold;              
    border-radius: 30px;           
    text-align: center;             
    transition: all 0.3s ease;     
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); 
    width:100%;
  }

  
.single-manage-btn {
  background: linear-gradient(45deg, #2c3e50, #4ca1af); /* Gradient from dark to light */
  color: white;
  width:100%;
}


  .btn, .single-edit-btn, .editing-save-btn, .editing-cancel-btn {
    padding: 8px 20px;
    font-size: 14px; 
  }
}


@media (max-width: 480px) {
  .userAccount-page__container {
    padding: 3rem 0.5rem; 
  }

  .Profile__image__user, .profile-placeholder {
    width: 120px;
    height: 120px; 
  }

  .account-edit-overlay {
    bottom: 20%; 
    left: 80%; 
    width: 70px;
    height: 20px; 
  }
}