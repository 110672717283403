.unsubscibedStockIdea__conatiner {
  margin: 0 auto;
  padding: 20px;
  max-width: 1200px;
  background-color: #f8f9fa;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.unsubscibed-stockidea_title_div {
  text-align: center;
  margin-bottom: 30px;
}

.unsubscibed-title {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  color: #2c3e50;
  text-transform: uppercase;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;
  border-bottom: 3px solid #f39c12;
  display: inline-block;
}

.unsubscibed_stockidea_card_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.unsubscibed__stockidea__stock-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.unsubscibed__stockidea__stock-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.unsubscribed_stock_content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.unsubscribed_heaidng_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.stock-company-name {
  font-size: 20px;
  color: #2c3e50;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 40%;
}

.unsubscibed__stock-prices {
  font-size: 16px;
  color: #333;
}

.unsubscribed_stock-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.stock-details {
  margin-top: 10px;
}

.font-semibold {
  font-weight: 600;
}

.freeSubscribe-msg__unsubscribe {
  margin-top: 15px;
  text-align: center;
}

.subscribe-button {
  background-color: #ffce31;
  color: black;
  padding: 10px 20px;
  border-radius: 8px;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #ffad1f;
  color: white;
}

.LockIcon {
  margin-left: 5px;
  font-size: 18px;
}

.blurred__unsubscribe {
  filter: blur(8px);
}

@media (max-width: 768px) {
  .unsubscibed_stockidea_card_container {
    grid-template-columns: 1fr;
  }

  .unsubscibed-title {
    font-size: 24px;
  }

  .stock-company-name {
    font-size: 18px;
    max-width: 75%;
  }
}
