.Financialcard {
  border: 1px solid #a5a2a2;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  margin: 5px;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--surface);
  width: calc((100% / 3) - 40px);
}

@media (max-width: 1200px) {
  .Financialcard {
    width: 90%;
  }
}
