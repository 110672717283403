body {
  font-family: "Roboto", sans-serif;
  /* color: ; */
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8f9fa;
}

/* Container */
.policy-page {
  max-width: 1200px;
  margin: 0px auto;
  padding: 40px;
  background-color: var(--surface);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
  animation: fadeIn 0.6s ease-out;
}

/* Headings */
.policy-title {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--on-surface);
  text-align: center;
  position: relative;
  animation: fadeInDown 0.5s ease;
}

.policy-title::after {
  content: "";
  height: 3px;
  width: 80px;
  display: block;
  margin: 15px auto 0;
  background-color: #2980b9;
  border-radius: 2px;
}

.policy-subtitle {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 30px;
  color: var(--on-surface-variant);
  text-align: center;
}

.section-titlee {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: var(--on-surface-variant);
}

.section-paragraphh {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
  line-height: 1.75;
}

/* Content within Sections */
.content {
  margin-top: 20px;
}

.content-paragraph {
  font-size: 1rem;
  margin-bottom: 10px;
  color: var(--on-surface-variant);
  line-height: 1.6;
}

.content-point {
  margin-left: 20px;
  margin-bottom: 10px;
  font-size: 1rem;
  color: var(--on-surface);
}

/* Table Styles */
.table-container {
  margin-top: 20px;
  overflow-x: auto;
}

.content-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.table-head {
  background-color: var(--primary);
  color: var(--on-primary);
}

.table-row {
  border-bottom: 1px solid var(--on-surface);
}

.table-heading {
  padding: 12px;
  font-size: 1rem;
  text-align: left;
}

.table-cell {
  padding: 12px;
  font-size: 1rem;
  color: var(--on-surface);
}

.table-cell-nil {
  padding: 12px;
  text-align: center;
  font-size: 1rem;
  color: var(--on-surface);
}

/* Grievance Section */
.grievance-section {
  margin-top: 50px;
  padding: 20px;
  background-color: var(--surface);
  border-left: 3px solid var(--on-surface);
  border-radius: 8px;
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(0.1s * var(--i));
}

.grievance-description,
.direct-grievance-text {
  font-size: 1rem;
  color: var(--on-surface-low);
  margin-bottom: 15px;
}

.sebi-scores-link,
.submit-grievance-btn {
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.sebi-scores-link:hover,
.submit-grievance-btn:hover {
  color: #1a5276;
}

.grievance-section p:last-child {
  font-size: 0.9rem;
  color: #777;
  text-align: right;
}

/* Animations */
@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
