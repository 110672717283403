.allportfolio__container {
    background: linear-gradient(135deg, #f7f8f9, #ffffff);
    border-radius: 7px;
    padding: 20px;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
    margin: 10px 0;
    transition: all 0.3s ease;
}

.allportfolio-title {
    text-align: left;
    font-size: 1.8rem;
    color: #424242;
    margin-bottom: 15px;
    font-weight: 700;
    border-bottom: 2px dotted black;
    padding-bottom: 10px;
}

.Allportfolio_notexpand_div,
.expanded-allportfolio_div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
}

.allportfolio-data-group {
    flex: 1 1 calc(25% - 10px); /* Adjust this to 25% for four columns */
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #ccc;
    transition: transform 0.2s ease-in-out;
}

.allportfolio-data-group:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.fist_div, .second_div {
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 7px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.allportfolio-unexpand_data__title {
    margin-bottom: 5px;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
}

.allportfolio-unexpand_data__data {
    margin-top: 10px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
}

.expand_button, .collapse_button {
    background-color: transparent;
    border: none;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    color: #424242;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
}

.expand_button:hover, .collapse_button:hover {
    color: #000000;
}

.KeyboardDoubleArrowDownIcon,
.KeyboardDoubleArrowUpIcon {
    margin-left: 10px;
}

@media only screen and (max-width: 768px) {
    .allportfolio__container {
        padding: 15px;
        margin: 10px 0;
        border-radius: 10px;
    }

    .allportfolio-title {
        font-size: 1.5rem;
        padding-bottom: 5px;
    }

    .Allportfolio_notexpand_div, .expanded-allportfolio_div {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .allportfolio-data-group {
        flex: 1 1 100%;
        padding: 10px;
    }

    .fist_div, .second_div {
        padding: 10px;
        margin-bottom: 10px;
    }

    .allportfolio-unexpand_data__title {
        font-size: 1rem;
    }

    .allportfolio-unexpand_data__data {
        font-size: 0.9rem;
    }

    .expand_button, .collapse_button {
        font-size: 1rem;
    }

    .KeyboardDoubleArrowDownIcon, .KeyboardDoubleArrowUpIcon {
        margin-left: 5px;
    }
}
