.fade-in {
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-20px);
  animation: fadeInLeft 1s ease-in-out forwards;
}

@keyframes fadeInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  opacity: 0;
  transform: translateX(20px);
  animation: fadeInRight 1s ease-in-out forwards;
}

@keyframes fadeInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.disclaimer-container {
  background: #ffffff;
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.disclaimerpage__title::after{
  content:'';
  display:block;
  height: 4px;
  width:80px;
  background-color: #2b6cb0;
    margin: 15px auto 0;
}
