.skeleton-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
  }
  
  .skeleton-card {
    width: 210px;
    padding: 1rem 0;
  }

  .skeleton-container {
    padding: 1rem;
  }
  
  .skeleton-card {
    margin: 1rem 0;
  }
  