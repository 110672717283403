.totalloan_chart_conatiner {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.totalloan_chart-and-data-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  height: 310px;
}

.total_loan_chart,
.total_loan_data-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total_loan_chart {
  width: 50%;
  /* Takes up half the width */
}

.total_loan_data-section {
  width: 50%;
  /* Takes up the remaining half */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.totalloan_chart-title_shariahdetailspage {
  text-align: center;
  margin: 2px 10px 10px;
  font-weight: bold;
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: #333333;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.5px;
  padding: 10px;
  font-size: 1rem;
}

.total_loan_percentage_title,
.totalLoan_percentage_data {
  text-align: center;
  margin: 2px 10px 10px;
  font-weight: bold;
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: var(--on-surface);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.5px;
  padding: 10px;
  font-size: 1rem;
}

/* Additional styling to ensure the gauge chart is responsive */
#gauge-chart {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .totalloan_chart-and-data-container {
    flex-direction: column;
  }

  .total_loan_chart,
  .total_loan_data-section {
    width: 100%;
  }

  .total_loan_percentage_title,
  .totalLoan_percentage_data {
    text-align: center;
    font-size: 1rem;
    padding: 10px;
  }
}
