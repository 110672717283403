.Shariah-page-container {
  background-color: var(--surface);
  color: var(--on-surface);
  padding: 2rem;
}

.shariah__page__secondcontainer {
  background-color: var(--surface);
  padding: 20px;
}

.fab-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.fab-button {
  background-color: #007bff;
  color: white;
  border: none;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.searchIcon_companydetails_flatingsearch {
  font-size: 24px;
}

.shiarah_company__name {
  font-size: 24px;
  font-weight: 800;
  color: var(--on-surface);
  background-color: var(--surface);
  text-align: left;
  padding: 10px;
}

.buinessmodel__conatiner {
  color: var(--on-surface);
  background-color: var(--surface);
  padding: 10px;
  border-radius: 7px;
  border-bottom: 1px solid grey;
}

.buinessmodel__title__div {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.business__model__text {
  font-size: 18px;
  font-weight: 700;
  color: var(--on-surface);
}

.businessmodel_point_one,
.businessmodel_point_two,
.businessmodel_point_three {
  padding: 10px 0;
  margin-left: 30px;
  margin-bottom: 10px;
}

.point__one__businessmodel,
.point__two__businessmodel,
.point__three__businessmodel {
  font-size: 16px;
  color: var(--on-surface-variant);
  font-weight: 600;
}

.business-permissibility-container {
  padding: 0.5rem;
  background-color: var(--surface);
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
}

.business-permissibility-title {
  font-size: 18px;
  font-weight: 700;
  color: var(--on-surface);
}

.business-permissibility-status {
  font-size: 1.125rem;
  margin-left: 0.5rem;
  border: 1px solid black;
  padding: 5px 15px;
  border-radius: 7px;
  color: var(--on-surface);
}

.business-permissibility-status.yes {
  background: linear-gradient(45deg, darkgreen, lightgreen);
}

.business-permissibility-status.no {
  background-color: #eb5b00;
}
.compliance-container {
  display: flex;
  flex-direction: row;
}

.compliance-card {
  width: 100%;
  padding: 10px;
  background-color: var(--surface);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  margin-top: 10px;
  max-width: 20%;
  margin-left: 10px;
  margin-bottom: 10px;
}

.compliance-details {
  margin-top: 20px;
}

.compliance-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.compliance-check p {
  margin: 0;
  font-size: 16px;
  flex: 1;
}

.financial-screening-container {
  width: 100%;
  padding: 10px;
  border-radius: 0.5rem;
}
.compliance-title {
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  color: var(--on-surface);
  padding: 5px;
  margin-top: 10px;
}

.financial-info-title {
  font-size: 18px;
  font-weight: 800;
  text-align: left;
  color: var(--on-surface);
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 10px;
}

.compliance-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compliance-status {
  margin-bottom: 10px;
  margin-top: 10px;
}

.compliant-logo,
.non-compliant-logo {
  width: 200px;
}

.compliance-result {
  font-size: 1.125rem;
  font-weight: 800;
  text-align: center;
}

.financial-info-card {
  background-color: var(--surface);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  border-radius: 0.5rem;
}

.shariah__backbutton_div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  height: 100%;
}

.previous__buttton__shariah__bottom {
  background-color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
  margin-right: 20px;
}

.previous__buttton__shariah__bottom:hover {
  background-color: green;
}

.backkey_shariahpage {
  font-size: 24px;
  color: white;
}

.business-permissibility-container_no_business-permissibility {
  margin-bottom: 18px;
  text-align: center;
  margin-top: 10px;
  background-color: var(--surface);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
}

.business-permissibility-title__no {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--on-surface);
}

.business-permissibility-status_no {
  font-size: 18px;
  font-weight: normal;
}

.business-permissibility-status_no.yes {
  color: green;
  font-weight: bold;
}

.business-permissibility-status_no.no {
  color: #eb5b00;
  font-weight: bold;
}

.reson__conatiner {
  margin-top: 10px;
  background-color: var(--surface);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  border-color: var(--on-surface);
}

.title__resaon {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: var(--on-surface);
  text-align: center;
}

.reseaonparah {
  font-size: 18px;
  color: #eb5b00;
  text-align: center;
}

.timeline-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 5px;
  padding: 10px;
  position: relative;
}

.timeline-container::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 2px;
  background-color: green;
  z-index: 1;
}

.timeline-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: linear-gradient(135deg, #ffffff, #f0f0f0);
  padding: 10px;
  z-index: 2;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: black; /* Changed text color for better contrast */
}

.step-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
}

.timeline-step h3 {
  margin: 0;
  font-weight: bold;
  border: 1px solid black;
  padding: 5px;
  border-radius: 7px;
  background: green;
  color: white;
  font-size: 18px;
}

.timeline-step p {
  margin: 0;
  font-weight: bold;
  font-size: 16px;
}

.tick-mark,
.cross-mark {
  font-size: 24px;
  margin-top: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0f0;
}

.tick-mark {
  color: green;
}

.cross-mark {
  color: #eb5b00;
}

@media (max-width: 768px) {
  .Shariah-page-container {
    background-color: var(--surface);
    color: var(--on-surface);
    padding: 0.5rem;
  }

  .shariah__page__secondcontainer {
    padding: 15=0px;
    margin-top: 10px;
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  .shiarah_company__name {
    font-size: 18px;
    text-align: left;
    padding: 8px;
    font-weight: 700;
  }

  .buinessmodel__conatiner {
    padding: 8px;
    border-radius: 5px;
  }

  .buinessmodel__title__div {
    padding-bottom: 4px;
    margin-bottom: 4px;
  }
  .business__model__text {
    font-size: 16px;
    font-weight: 600;
  }
  .businessmodel_point_one,
  .businessmodel_point_two,
  .businessmodel_point_three {
    padding: 5px 0;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  .point__one__businessmodel,
  .point__two__businessmodel,
  .point__three__businessmodel {
    font-size: 14px;
    font-weight: 500;
  }
  .business-permissibility-title {
    font-size: 16px;
    font-weight: 600;
  }

  .business-permissibility-status {
    font-size: 14px;
    margin-left: 0.5rem;
    border: 1px solid black;
    padding: 5px 10px;
    border-radius: 7px;
    color: white;
  }

  .compliance-container {
    display: flex;
    flex-direction: column;
  }

  .compliance-card {
    width: 100%;
    padding: 10px;
    background-color: var(--surface);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    margin-top: 10px;
    margin-left: 0;
    margin-bottom: 5px;
    max-width: 1500px;
  }

  .compliance-details {
    margin-top: 20px;
  }

  .compliance-check {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .compliance-check p {
    margin: 0;
    font-size: 14px;
    flex: 1;
  }

  .financial-screening-container {
    width: 100%;
    padding: 10px;
    border-radius: 0.5rem;
    margin-top: 5px;
    max-width: 1500px;
  }

  .compliance-title {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
    color: var(--on-surface);
    padding: 5px;
    margin-top: 10px;
  }

  .financial-info-title {
    font-size: 16px;
    font-weight: 800;
    text-align: left;
    color: var(--on-surface);
    margin-top: 0;
    margin-bottom: 20px;
    padding: 10px;
  }

  .compliance-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .compliance-status {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .compliant-logo,
  .non-compliant-logo {
    width: 150px;
  }

  .compliance-result {
    font-size: 1rem;
    font-weight: 800;
    text-align: center;
  }

  .financial-info-card {
    background-color: var(--surface);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .business-permissibility-container_no_business-permissibility {
    margin-bottom: 5px;
    margin-top: 0px;
    padding: 10px;
    background-color: var(--surface);
  }
  .business-permissibility-title__no {
    font-size: 16px;
    margin-bottom: 5px;
  }
  .business-permissibility-status_no {
    font-size: 16px;
  }
  .reson__conatiner {
    margin-top: 0px;
    padding: 10px;
    border-radius: 5px;
  }
  .title__resaon {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .reseaonparah {
    font-size: 14px;
    color: #eb5b00;
    text-align: center;
  }
  .previous__buttton__shariah__bottom {
    padding: 5px 10px;
    border-radius: 7px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .timeline-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .timeline-container::before {
    top: auto;
    left: 50%;
    height: 100%;
    width: 2px;
  }

  .timeline-step {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 10px 0;
  }

  .step-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 60px);
    margin-bottom: 0;
  }

  .timeline-step h3 {
    font-size: 16px;
    padding: 3px;
  }

  .timeline-step p {
    font-size: 14px;
  }

  .tick-mark,
  .cross-mark {
    margin-left: auto;
  }
}
