@keyframes fadeInOut {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.animate-fade {
  display: inline-block;
  animation: fadeInOut 4s infinite;
}

.placeholder-text {
  font-size: 0.875rem; /* Tailwind CSS equivalent for text-sm */
  color: #9ca3af; /* Tailwind CSS equivalent for text-gray-500 */
  position: absolute;
  left: 2.5rem;
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
}
