::selection {
    color: var(--on-primary-container);
    background-color: var(--primary-container);
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: var(--surface-container-lowest);
}
::-webkit-scrollbar-thumb {
    background: var(--surface-container);
    border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--surface-containe-high);
}