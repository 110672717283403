
.news-container__newComponent {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow: hidden;
  
}

.news_component_title {
  padding: 10px;
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  text-align: left;
}

.news_component__unorderedlist {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  overflow-x: auto;
  white-space: nowrap;
}

.news_component__list_data {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  padding: 20px;
  width: 300px;
  margin-right: 20px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
}

.news_component__list_data:last-child {
  margin-right: 0;
}

.newscomponent__headline {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  word-wrap: break-word; 
  overflow-wrap: break-word; 
  white-space: normal; 
}

.news_Compoentn__linkingurl {
  text-decoration: none;
  color: #00cc07;
  display: block;
  margin-bottom: 20px;
  text-align: right;
}

.news_Compoentn__linkingurl:hover {
  color:blue;
  font-weight: 600;
}

.newscomponent__date_time {
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 10px;
  left: 20px;
  right: 20px;
}

.newscomponent__time {
  font-size: 14px;
  color: #666;
}

.newscomponent__date {
  font-size: 14px;
  color: #666;
}


@media (max-width: 600px) {
  .news_component_title {
      font-size: 18px;
  }

  .news_component__list_data {
      padding: 15px; 
      width: 260px; 
  }

  .newscomponent__headline {
      font-size: 15px; 
  }

  .news_Compoentn__linkingurl {
      font-size: 14px; 
  }

  .newscomponent__date_time {
      font-size: 12px; 
      bottom: 5px; 
      left: 15px;
      right: 15px;
  }

  .newscomponent__time, .newscomponent__date {
      font-size: 12px; 
  }
}


