.progression{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 32px;
}
.progression__item{
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;
}
.progression__icon{
    display: flex;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 2px solid var(--on-surface-low);
    z-index: 10;
    transition: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
}
.progression__bar{
    display: flex;
    position: absolute;
    justify-content: center;
    top: 0px;
    width: 32px;
    height: 100%;
    padding: 32px 0px;
}
.progression__thumb{
    display: flex;
    width: 4px;
    border-radius: 2px;
    background-color: var(--primary-variant);
}
.progression__icon--selected{
    background-color: var(--primary-variant);
    border: 2px solid var(--primary-variant);
}