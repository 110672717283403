[data-theme="light"] {
    --font-weight-display: 500;
    --font-weight-heading: 500;
    --font-weight-title: 500;
    --font-weight-body: 400;
    --font-weight-label: 400;
}

[data-theme="dark"] {
    --font-weight-display: 400;
    --font-weight-heading: 400;
    --font-weight-title: 400;
    --font-weight-body: 300;
    --font-weight-label: 300;
}