.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  backdrop-filter: blur(5px); /* Increased blur */
  position: fixed;
  width: 100%;
  max-width:1500px;
  top: 0;
  left: 0;
}

.loading-content {
  display: flex;
  align-items: center;
}

@keyframes blink {
  0%, 100% {
    opacity: 0.2;
    color: black; /* Initial and final color */
  }
  20% {
    opacity: 1;
    color: green; /* Color at the peak of the blink */
  }
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  animation: blink 1.4s infinite both;
}

.loading-text:nth-child(2) {
  animation-delay: 0.2s;
}

.loading-text:nth-child(3) {
  animation-delay: 0.4s;
}

.loading-text:nth-child(4) {
  animation-delay: 0.6s;
}

.loading-text:nth-child(5) {
  animation-delay: 0.8s;
}

.loading-text:nth-child(6) {
  animation-delay: 1s;
}

.icon-animation {
  position: relative;
  margin-left: 10px;
  width: 36px;
  height: 36px;
}

.icon-animation svg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  font-size: 36px;
  opacity: 0;
  animation: rotateIcon 9s infinite both;
}

.icon-animation svg:nth-child(1) {
  animation-delay: 0s;
}

.icon-animation svg:nth-child(2) {
  animation-delay: 3s;
}

.icon-animation svg:nth-child(3) {
  animation-delay: 6s;
}

@keyframes rotateIcon {
  0%, 33.33%, 100% {
    opacity: 0;
    transform: rotate(0deg);
  }
  16.67%, 50% {
    opacity: 1;
    transform: rotate(360deg);
  }
}
