.pill{
    display: flex;
    width: fit-content;
    height: 32px;
    padding: 0px 12px;
    gap: 4px;
    justify-content: center;
    align-items: center;
    transition: all;
    transition-duration: 100ms;
    transition-timing-function: ease-in-out;
    border-radius: 16px;
}
.pill__label{
    font-family: "Funnel Sans";
    font-size: 16px;
    font-weight: 400;
    padding: 0px 4px;
    cursor: pointer;
}
.pill__icon{
    font-family: "Material Symbols Rounded";
    font-size: 16px;
    font-variation-settings: 
    'FILL' 0, 
    'wght' 400, 
    'GRAD' 0, 
    'opsz' 20;
}
.pill--color{
    color: var(--on-surface);
    background-color: var(--surface-container);
}
.pill--color:hover{
    color: var(--on-primary-container);
    background-color: var(--primary-container);
}