.grid {
  display: grid;
}
.grid--col-2 {
  grid-template-columns: 1fr 1fr;
}
.grid--col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 768px) {
  .grid {
    display: grid;
    gap: 16px; /* Add spacing between grid items for better readability */
  }

  /* Adjust 2-column grid to a single column on mobile */
  .grid--col-2 {
    grid-template-columns: 1fr;
  }

  /* Adjust 3-column grid to a single column on mobile */
  .grid--col-3 {
    grid-template-columns: 1fr;
  }
}
