.cancel-subscription-container {
    margin-bottom: 5px;
    margin-top:20px;
  }

  .container_cancelsubscription__button {
    margin-top: 0px;
    background: linear-gradient(135deg, #1a1a1a, #333333); /* Dark gradient */
    padding: 10px;
    border-radius: 10px;
    color: white; /* Ensure text is readable */
  }
  .managesusbcription__currentplan {
    text-align: left;
    border-bottom: 1px solid white; /* Add underline */
    margin-bottom: 10px; /* Space below the title */
    font-size:1.1rem
  }
  
  .cancel-subscription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .left-side {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .cancel_subscription_typography {
    font-size:0.9rem;
    color: white;
    padding: 5px;
  }
  
  .renewdate_parah{
    margin: 0; /* Remove margin for better alignment */
    font-size:0.7rem;
    padding: 5px;
  }
  
  .cancel_subscription__button {
    padding: 5px 10px;
    background-color: red;
    border-radius: 7px;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .cancel_subscription__button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  

  .cancel-subscription-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cancel-subscription-box {
    position: 'absolute';
    top: '50%';
    left: '50%';
    transform: 'translate(-50%, -50%)';
    width: 600px;
    background: linear-gradient(135deg, #000000, black);
    box-shadow: 24px;
    padding: 16px;
    border-radius:15px;
  }

  .cancelsubscription_confirmtitle {
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 1.4rem;
    color: white;
  }
  
  /* CSS for the message */
  .cancel-subscription-message {
    margin-bottom: 20px;
    color: grey;
    font-size: 1rem;
  }
  
  .cancel-subscription-confirm__confirmmodel {
    background-color: #d32f2f;
    color: white;
    margin-right: 10px;
    padding: 5px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
    margin-top:20px;
  }
  
  .cancel-subscription-confirm__confirmmodel:hover {
    background-color: #b71c1c;
  }
  
  /* CSS for the close button */
  .cancel-subscription-close__confirmmodel {
    background-color: #1976d2;
    color: white;
    padding: 5px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .cancel-subscription-close__confirmmodel:hover {
    background-color: #1565c0;
  }

  .cancelsubscription_confirmtitle {
    text-align:center;
  }

  