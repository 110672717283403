.quickwin_landingpage__main_container{
  margin-top: 5rem;
}

/* .QuickWin__plans_container__component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; 
  padding: 10px;
  margin-top: 5rem;
} */

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

/* Add this to your CSS file */
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s ease-in-out infinite;
}


.modal-plan-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.modal-plan-summary-box {
  background-color: black;
  width: 1000px !important;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  position: relative;
  margin: auto;
}

.modal-plan-summary-header {
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal-plan-summary-closee-button {
  color: white;
}

.modal-plan-summary-title {
  text-transform: capitalize;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: left;
}

.modal-plan-summary-price {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: left;
}

.modal-plan-summary-price-big {
  font-size: 35px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.modal-plan-summary-price-small {
  font-size: 16px;
  display: inline-block;
  vertical-align: bottom;
  margin-left: 0px;
}

.modal-plan-summary-period {
  font-size: 14px;
  margin-bottom: 60px;
  text-align: left;
}

.modal-plan-summary-terms {
  font-size: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.modal-plan-summary-grid {
  margin-bottom: 20px;
}

.modal-plan-summary-checkbox {
  border-radius: 50%;
  color:white
}



.modal-plan-summary-checkbox-item {
  display: flex;
  align-items: center;
  color:white !important;
}

.modal-plan-summary-terms-text {
  margin-left: 10px;
}

.modal-plan-summary-button {
  background: linear-gradient(45deg, #098e16 30%, #1fbc0e 90%);
  border-radius: 35px !important;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  display: block;
  margin: 0 auto;
  margin-top: 30px !important;
}

@media (max-width: 768px) {
  .modal-plan-summary-box {
  
    max-width: 90% !important;
    padding: 20px;
  }

  .modal-plan-summary-header {
    position: absolute;
    top: 10px;
    right: 10px;
    
  }
  
  .modal-plan-summary-closee-button {
    color: white;
    padding:10px;
  }

  .modal-plan-summary-title {
    font-size: 20px;
  }

  .modal-plan-summary-price {
    font-size: 16px;
  }

  .modal-plan-summary-price-big {
    font-size: 28px;
  }

  .modal-plan-summary-price-small {
    font-size: 12px;
  }

  .modal-plan-summary-period {
    font-size: 12px;
  }

  .modal-plan-summary-terms {
    font-size: 10px;
    margin-top: 10px;
  }

  .modal-plan-summary-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

.custom-dialog-actions .quickwin-starter__custom-dialog-ok-button {
  background-color: green; 
  color: white; 
  border-radius: 7px; 
  padding: 5px 20px;
  border: none; 
  outline: none; 
  cursor: pointer; 
}

.custom-dialog-actions .quickwin-starter__custom-dialog-ok-button:hover {
  background-color: green; 
}


.termandcondtion__link_quickwin {
  text-decoration: none;
}


.registerationclose-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: 2px solid black; 
  border-radius: 50%;
  color: black;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, color 0.3s;
}

.registerationclose-button:hover {
  background-color: red;
  color: white;
}

