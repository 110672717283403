.Home-page-container {
  padding-top: 5rem;
  padding-bottom: 12rem;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.homepage__second_container {
  padding: 1rem
}


.Welcome_text_div_home {
  width: 100%;
  margin-top: 0px;
}

.hello-text {
  font-family: 'Roboto', sans-serif;
  font-size: 30px;
  color: #000000;
  text-align: center;
  margin-bottom: 10px;
}

.ourImage {
  height: 70px;
  width: 150px;
  object-fit: contain;
  margin-left: 20px;
}

.Home_page_search_div {
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1px;
}

.Finvestshariah_name {
  display: inline-block;
  vertical-align: middle;
  font-size: auto;
  font-weight: bold;
  color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-family: 'Times New Roman', Times, serif;
}

.home_page__sugested_companies {
  width: 50%;
  margin: auto;
}

.suggested-companies {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

}

.Suggested_title {
  font-weight: bold;
  white-space: nowrap;
}

.suggested-company {
  padding: 5px 10px;
  background-color: var(--surface);
  color: #000000;
  font-size: 0.8rem;
  border-radius: 7px;
  white-space: nowrap;
  margin-bottom: 5px;
  cursor: pointer; 
 
}


.suggested-company:hover {
  background-color: #c4ffe1;
 
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}


@media (max-width: 768px) {
  .Home-page-container {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }

  .Welcome_text_div_home {
    width: 100%;

  }

  .hello-text {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .ourImage {
    height: 40px;
    width: 120px;
    object-fit: contain;
    margin-left: 20px;
  }

  .Home_page_search_div {
    width: 100%;
    margin-bottom: 2rem;
  }



  .Home_page_table_title {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .Finvestshariah_name {
    font-size: 20px;
  }
}

.Finvestshariah_name {
  display: inline-block;
  vertical-align: middle;
  font-size: auto;
  font-weight: bold;
  color: #000000;
  padding: 10px 20px;
  border-radius: 5px;
  transition: all 0.3s ease;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}


@media (min-width: 320px) and (max-width: 740px) {
  .Finvestshariah_name {
    display: inline-block;
    vertical-align: middle;
  }
}

@keyframes fadeInMove {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.hello-text {
  animation: fadeInMove 2s ease-out forwards;
}

.ourImage {
  animation: fadeInMove 2s ease-out 0.5s forwards;
}


.mobile-content {
  display: none;
}

@media screen and (max-width: 767px) {
  .mobile-content {
    display: inline-block;
    font-size: 18px;
    margin-left: 10px;
  }

  .Finvestshariah_name {
    display: none;
  }
}

@media screen and (max-width: 320) {
  .homepagehtwo {
    margin-top: 2rem;
  }
}


@keyframes skeleton-shine {
  0% {
    background-position: -300px 0;
  }

  100% {
    background-position: calc(300px + 100%) 0;
  }
}

.MuiSkeleton-root {
  background-image: linear-gradient(90deg,
      rgba(0, 0, 0, 0.06) 25%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(0, 0, 0, 0.06) 75%);
  background-size: 400px 100%;
  animation: skeleton-shine 2s infinite ease;
}

@media (max-width: 768px) {
  .home_page__sugested_companies {
    width: 100%;
    margin: auto;
    padding: 5px;
  }

  .suggested-companies {
    gap: 5px;
  }

  .Suggested_title {
    font-weight: bold;
    font-size: 0.8rem;
    white-space: nowrap;
    margin-right: auto;
 
  }

  .suggested-company {
    padding: 5px 10px;
 
    background-color: #d2eeff;

    color: #333;
  
    font-size: 0.6rem;

    border-radius: 7px;
   
    white-space: nowrap;
    margin-bottom: 5px;
  
  }


  .suggested-company:hover {
    background-color: #c4ffe1;
    
  }
}