.investors-portal-container {
  width: 100%;
  max-width: 1400px; 
  margin: 0 auto; 
  padding: 15px; 
}

.grievances-section {
  width: 100%;
}



.form-overlay-container {
  position: relative;
}

.loading-container, .backdrop-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8); 
}

.success-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.success_message_giverance{
  font-weight: bold;
  margin-bottom: 20px;
}




.close-button {
  cursor: pointer;
  padding: 5px 5px;
  background-color: #5cb85c;
  border: none;
  border-radius: 5px;
  color: white;
  margin-top: 10px;
}

.grievance-form {
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box; 
}

.form-group {
  width: 100%;
  margin-bottom: 20px;
}

.input-name, .input-email, .select-category, .input-subject, .textarea-message {
  width: 100%;
  box-sizing: border-box; 
  padding: 10px; 
  margin-top: 5px;
}

.submit-button {
  width: auto; 
  padding: 10px 20px; 
  cursor: pointer;
}


@media (max-width: 768px) {
  .form-group {
    margin-bottom: 15px; 
  }

  .input-name, .input-email, .select-category, .input-subject, .textarea-message {
    padding: 8px; 
  }

  .submit-button {
    padding: 8px 16px; 
  }
}


@media (max-width: 480px) {
  .form-group {
    margin-bottom: 10px;
  }

  .input-name, .input-email, .select-category, .input-subject, .textarea-message {
    padding: 5px;
  }

  .submit-button {
    padding: 6px 12px;
  }
}

