/* LoadingSpinner.css */
.loading-spinner__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-bouncing-dots {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-bouncing-dots div {
  width: 12px;
  height: 12px;
  margin: 0 5px;
  background: #333;
  border-radius: 50%;
  animation: lds-bouncing-dots 0.6s infinite alternate;
}

.lds-bouncing-dots div:nth-child(2) {
  animation-delay: 0.2s;
}

.lds-bouncing-dots div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes lds-bouncing-dots {
  to {
    transform: translateY(-16px);
  }
}
