.watchlist_table_container {
    max-width: 100%;
    overflow-x: auto;
    margin: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.watchlist_table_scroll {
    max-height: 500px;
    overflow-y: auto;
}

.watchlist_table {
    width: 100%;
    background-color: white;
}

.watchlist_table_header {
    background-color: rgb(28, 28, 45) !important;
}

.header_cell_serial,
.header_cell_company,
.header_cell_shariah,
.header_cell_currentprice,
.header_cell_pricechange,
.header_cell_Marketcap,
.header_cell_revenuegrowth,
.header_cell_profitgrowth,
.header_cell_peratio,
.header_cell_pbratio,
.header_cell_actionheader {
    color: white !important;
    font-weight: bold;
    text-align: center !important;
    font-size: 16px !important;
}

.header_cell_serial {
    width: 100px;
}

.header_cell_company {
    width: 200px !important;
}

.header_cell_shariah {
    width: 180px !important;
}

.header_cell_currentprice {
    width: 170px;
}

.header_cell_pricechange {
    width: 130px
}

.header_cell_Marketcap {
    width: 180px !important;
}

.header_cell_revenuegrowth {
    width: 200px
}

.header_cell_pbratio {
    width: 180px;
}

.watchlist_table_row {
    background-color: white;
}

.data_cell_serial,
.data_cell_company,
.data_cell_status,
.data_cell_currentPrice,
.data_cell_pricechnage,
.data_cell_marketcap,
.data_cell_salesgrowth,
.data_cell_profitgrowth,
.data_cell_peratio,
.data_cell_pbratio,
.data_cell_actions {
    color: black;
    padding: 15px !important;
    text-align: center !important;
    font-size: 16px !important;
    font-weight: bold !important;

}

.Watchlist_compnayName_muitable {
    text-decoration: none;
    color: brown;
    padding: 0px;
}

.data_cell_company {
    text-align: left;
}

.watchlist__currentprice h4 {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.watchlist__pricechange_css p {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.watchlist_css_marketcap {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.watchlistcss__peratio {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.watchlistcss__peratio {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.watchlistcss__pbratio {
    font-size: 16px;
    font-weight: bold;
    padding: 0px;
    margin: 0px;
}

.data_cell_marketcap {
    width: 200px
}

.data_cell_salesgrowth,
.data_cell_profitgrowth {
    width: 180px !important;
}

.data_cell_peratio,
.data_cell_pbratio {
    width: 150px
}

.watchlist_delte__icon {
    color: rgb(142, 23, 23) !important;
}

.watchlist_delte__icon:hover {
    color: #ff0101 !important;
    
}

.MuiTableHead-root .MuiTableCell-head {
    top: 0;
    position: sticky;
    z-index: 2;
    background-color: rgb(31, 31, 49) !important;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}


.custom-dialog__watchlistdelte_dialog .MuiPaper-root {
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}

.custom-dialog-title_watchlistdelete {
    color: #d50000;
    font-size: 24px !important;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.custom-dialog-content__watchlistdelete {
    color: #272727;
    font-size: 16px;
}

.custom-dialog-content-text__watchlist {
    text-align: center;
    margin-bottom: 30px;
}

.custom-dialog-actions__watchlist {
    justify-content: center;
    padding: 20px;
}

.custom-dialog-cancel-button.watchlist {
    background-color: #f44336;
    color: white;
    text-transform: none;
    font-size: 16px;
    margin-right: 20px;

}

.custom-dialog-cancel-button.watchlist:hover {
    background-color: #d32f2f;
}

.custom-dialog-confirm-button__watchlist {
    background-color: #5aa75c !important;
    color: rgb(253, 253, 253) !important;
    text-transform: none;
    font-size: 16px;
}

.custom-dialog-confirm-button__watchlist:hover {
    background-color: #388e3c !important;
}


@media only screen and (max-width: 768px) {
    .watchlist_table_container {
        max-width: 100%;
        overflow-x: auto;
        margin: 5px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    }

    .watchlist_table_scroll {
        max-height: 500px;
        overflow-y: auto;
    }

    .watchlist_table {
        width: 100% !important;
        background-color: white;
    }

    .watchlist_table_header {
        background-color: rgb(28, 28, 45) !important;
    }

    .header_cell_serial,
    .header_cell_company,
    .header_cell_shariah,
    .header_cell_currentprice,
    .header_cell_pricechange,
    .header_cell_Marketcap,
    .header_cell_revenuegrowth,
    .header_cell_profitgrowth,
    .header_cell_peratio,
    .header_cell_pbratio,
    .header_cell_actionheader {
        color: white !important;
        font-weight: bold;
        text-align: center !important;
        font-size: 14px !important;
    }

    .header_cell_serial {
        width: 100px !important;
    }

    .header_cell_company {
        width: 240px !important;
    }

    .header_cell_shariah {
        width: 180px !important;
    }

    .header_cell_currentprice {
        width: 170px;
    }

    .header_cell_pricechange {
        width: 130px
    }

    .header_cell_Marketcap {
        width: 180px !important;
    }

    .header_cell_revenuegrowth {
        width: 200px
    }

    .header_cell_pbratio {
        width: 180px;
    }

    .watchlist_table_row {
        background-color: white;
    }

    .data_cell_serial,
    .data_cell_company,
    .data_cell_status,
    .data_cell_currentPrice,
    .data_cell_pricechnage,
    .data_cell_marketcap,
    .data_cell_salesgrowth,
    .data_cell_profitgrowth,
    .data_cell_peratio,
    .data_cell_pbratio,
    .data_cell_actions {
        color: black;
        padding: 10px !important;
        text-align: center !important;
        font-size: 14px !important;
        font-weight: bold !important;

    }

    .Watchlist_compnayName_muitable {
        text-decoration: none;
        color: brown;
        padding: 0px;
    }

    .data_cell_company {
        text-align: left;
    }

    .watchlist__currentprice h4 {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .watchlist__pricechange_css p {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .watchlist_css_marketcap {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .watchlistcss__peratio {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .watchlistcss__peratio {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .watchlistcss__pbratio {
        font-size: 14px;
        font-weight: bold;
        padding: 0px;
        margin: 0px;
    }

    .data_cell_marketcap {
        width: 200px
    }

    .data_cell_salesgrowth,
    .data_cell_profitgrowth {
        width: 180px !important;
    }

    .data_cell_peratio,
    .data_cell_pbratio {
        width: 150px !important;
    }

    .watchlist_delte__icon {
        color: red !important;
    }

    .MuiTableHead-root .MuiTableCell-head {
        top: 0;
        position: sticky;
        z-index: 2;
        background-color: rgb(31, 31, 49) !important;
    }

    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    .custom-dialog__watchlistdelte_dialog .MuiPaper-root {
        padding: 15px;
    }

    .custom-dialog-title_watchlistdelete,
    .custom-dialog-content__watchlistdelete,
    .custom-dialog-content-text__watchlist {
        font-size: 18px;
    }

    .custom-dialog-actions__watchlist button {
        font-size: 14px;
        padding: 6px 12px;
    }
}
