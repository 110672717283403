
.container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  margin-top:3rem
}

.header {
  text-align: left;
  margin-bottom: 20px;
  font-size:30px;
}

.user-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  justify-content: center;
  padding: 10px;
}

.user-card {
  background: linear-gradient(145deg, rgba(40, 40, 40, 0.7), rgba(10, 10, 10, 0.7)); /* Gradient from light black to dark black */
  backdrop-filter: blur(40px); /* Glass effect with stronger blur */
  padding: 20px;
  border-radius: 7px;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  border: 1px solid rgba(255, 255, 255, 0.2); /* Optional: for a subtle border */
}


.user-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.3);
}


.user-details {
  text-align: left;
}

.user-name {
  margin: 10px 0;
  color:#06D001;
  font-weight:bold;
}

.user-info {
  margin: 10px 0;
  color:#06D001
}

