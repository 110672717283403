

.QuickWin__plans_container__component {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 20px;
  gap: 20px; 
}

.plan__card {
  position: relative; 
  flex: 1 1 30%; 
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.plan__card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
}

.Subscrition__plan__name__quickwin{
    text-align: center;
    font-weight: bold;
}

.subscription__plan__period{
    font-weight: 600;
    font-size: 1.5rem;
    padding:10px;
    width:300px;
    border-radius: 7px;
}

.subscription__plan__price{
    font-size: 1.6rem;
    text-align: center;
    padding: 10px;
}

.subscription__plan__description{
   padding:10px;
}

.subscription__plan__pointA,
.subscription__plan__pointB,
.subscription__plan__pointC,
.subscription__plan__pointD,
.subscription__plan__pointE,
.subscription__plan__pointF,
.subscription__plan__pointG,
.subscription__plan__pointH {
    padding: 10px;
}



.subscription__button {
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 16px;
  padding: 12px;
  border-radius: 25px;
  text-align: center;
  transition: background-color 0.3s;
}

.subscription__button.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.subscription__button:not(.disabled):hover {
  background-color: #5dc486;
}
