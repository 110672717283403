.filled-button {
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  overflow: hidden;
}
.filled-button-primary {
  background-color: var(--primary-variant);
  color: var(--on-primary-variant);
}
.filled-button-neutral {
  background-color: var(--surface-inverse);
  color: var(--on-surface-inverse);
}
.filled-button--medium {
  height: 48px;
  border-radius: 24px;
}
.filled-button--small {
  height: 40px;
  border-radius: 20px;
}
.filled-button__label {
  font-family: "Funnel Sans", "Sans serif";
  font-size: 20px;
  font-weight: 400;
  padding: 0px 8px 2px 8px;
}
.filled-button__icon {
  font-family: "Material Symbols Rounded";
  font-size: 24px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.filled-button--icon-start {
  flex-direction: row;
}
.filled-button--icon-end {
  flex-direction: row-reverse;
}
.filled-button__state {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--surface);
  transition: all;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}
.filled-button__state--opacity {
  opacity: 0;
}
.filled-button__state--opacity:hover {
  opacity: 0.1;
}

.tonal-button {
  display: flex;
  position: relative;
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  background-color: var(--primary-container);
  color: var(--on-primary-container);
  overflow: hidden;
}
.tonal-button--medium {
  height: 48px;
  border-radius: 24px;
}
.tonal-button--small {
  height: 40px;
  border-radius: 20px;
}
.tonal-button__label {
  font-family: "Funnel Sans", "Sans serif";
  font-size: 20px;
  padding: 0px 8px 2px 8px;
}
.tonal-button--icon-start {
  flex-direction: row;
}
.tonal-button--icon-end {
  flex-direction: row-reverse;
}
.tonal-button__state {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--on-surface);
  transition: all;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}
.tonal-button__state--opacity {
  opacity: 0;
}
.tonal-button__state--opacity:hover {
  opacity: 0.07;
}

.ghost-button {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--primary);
  gap: 8px;
}
.ghost-button--medium {
  height: 48px;
  border-radius: 24px;
}
.ghost-button--small {
  height: 40px;
  border-radius: 20px;
}
.ghost-button__label {
  font-family: "Funnel Sans", "Sans serif";
  font-size: 20px;
  padding: 0px 0px 2px 0px;
  cursor: pointer;
}
.ghost-button--icon-start {
  flex-direction: row;
}
.ghost-button--icon-end {
  flex-direction: row-reverse;
}

.icon-button {
  display: flex;
  flex-shrink: 0;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
}
.icon-button--primary-filled {
  background-color: var(--primary-variant);
  color: var(--on-primary-variant);
}
.icon-button--primary-tonal {
  background-color: var(--primary-container);
  color: var(--on-primary-container);
}
.icon-button--primary-ghost {
  color: var(--primary);
}
.icon-button--neutral-filled {
  background-color: var(--neutral);
  color: var(--on-neutral);
}
.icon-button--neutral-tonal {
  background-color: var(--neutral-container);
  color: var(--on-neutral-container);
}
.icon-button--neutral-ghost {
  color: var(--on-surface);
}
.icon-button--medium {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}
.icon-button--small {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.icon-button__state {
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  transition: all;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
}
.icon-button__state--filled {
  background-color: var(--surface);
}
.icon-button__state--tonal {
  background-color: var(--on-surface);
}
.icon-button__state--ghost {
  background-color: var(--on-surface);
}
.icon-button__state--opacity {
  opacity: 0;
}
.icon-button__state--opacity:hover {
  opacity: 0.07;
}
@media (max-width: 768px) {
  .button {
    display: inline-flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    padding: 10px 16px; /* Adjust padding for smaller screens */
    font-size: 16px; /* Scale down font size */
    gap: 6px; /* Reduce spacing between text and icon */
    width: 100%; /* Full width button for mobile */
    border-radius: 24px; /* Slightly less rounded for better fit */
    box-sizing: border-box; /* Ensure consistent sizing */
  }

  .button__icon {
    font-size: 18px; /* Adjust icon size for smaller screens */
    flex-shrink: 0; /* Prevent icon from resizing */
  }

  .button--full-width {
    width: 100%; /* Ensure button takes the full width on mobile */
  }
}
