.termandcon-container {
  background-color: var(--surface);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.termsncon {
  font-size: 2rem;
  font-weight: 700;
  color: var(--on-surface);
  margin-bottom: 2rem;
  text-align: center;
  position: relative;
  animation: fadeInDown 0.5s ease;
}

.termsncon::after {
  content: "";
  display: block;
  width: 70px;
  height: 4px;
  background-color: #3498db;
  margin: 15px auto 0;
}

.section-container {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(0.1s * var(--i));
}

.heading-style {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--on-surface);
  margin: 2rem 0 1rem;
  padding-left: 20px;
  border-left: 5px solid #3498db;
  transition: all 0.3s ease;
}

.heading-style:hover {
  padding-left: 25px;
  border-left: 6px solid #3498db;
}

.subheading-style {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--on-surface);
  margin: 1.5rem 0 1rem;
  padding-left: 15px;
  border-left: 4px solid #2ecc71;
  transition: all 0.3s ease;
}

.subheading-style:hover {
  padding-left: 20px;
  border-left: 5px solid #2ecc71;
}

.content-style,
.subcontent-style {
  font-size: 1rem;
  color: var(--on-surface-variant);
  line-height: 1.75;
  margin-bottom: 1.5rem;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  color: var(--on-surface);
}

.spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
