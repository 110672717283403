.finsha-watchlist-container {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 10rem;
  padding-top: 7rem;
}


.watchlist-heading__title {
  text-align: center;
  color: #000000;
  font-weight: 600;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  animation: slideDownFadeIn 1s ease-out;
  padding: 20px;
  font-size:1.3rem
}

.watchlist-empty-message {
  text-align: center;
  padding: 30px;
  border: 1px dashed #179ef9;
  margin-top: 20px;
  background-color: #ffffff;
  margin-bottom: 2rem;
}

@keyframes slideFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.empty-watchlist-heading {
  animation: slideFadeIn 1s forwards;
  opacity: 0;
  color: red;
}


.watchlist-empty-message p {
  color: #2f3132;
}


.Main_Watchlist_Conatiner {
  border-radius: 5px;
  margin: 0px
}


@media only screen and (max-width: 768px) {

  .finsha-watchlist-container {
    padding: 5px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .watchlist-heading__title {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .watchlist-empty-message h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .watchlist-empty-message p {
    font-size: 14px;
  }

}