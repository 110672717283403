.subscription-status-container__marketview {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-radius: 8px;
  background-color: #282c34; /* Example background color */
  color: white;
  margin-top: 5px;
}

.subscription-status-header__marketview {
  font-size: 16px;
  margin: 0;
}

.subscription-status-toggle__marketview {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  width: 80px; /* Increased width */
  height: 32px; /* Increased height */
  border: 1px solid white;
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
  background-color: transparent;
  color: white;
}

.toggle-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.toggle-button .toggle-circle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: white;
  margin-right: 5px;
}

.toggle-button .toggle-label {
  font-size: 0.875rem;
}

.toggle-button.enabled .toggle-circle {
  background-color: #06D001;
}

.custom-dialog {
  max-width: 500px;
  margin: auto;
}

.custom-dialog-title {
  background-color: #f5f5f5;
  padding: 16px;
  font-size: 1.25rem;
  font-weight: bold;
}

.custom-dialog-content {
  padding: 16px;
  font-size: 1rem;
}

.custom-dialog-actions {
  padding: 8px 16px;
  display: flex;
  justify-content: flex-end;
}

.custom-dialog-actions .MuiButton-root {
  background-color: #1976d2;
  color: #fff;
}

.custom-dialog-actions .MuiButton-root:hover {
  background-color: #1565c0;
}
