.full-article-container {
  max-width: 1200px;
  margin: 100px auto;
  padding: 40px;
  border-radius: 12px;
  animation: fadeIn 0.6s ease-out;
}

.article-title {
  font-size: 28px;
  margin-bottom: 2px;
  color: #000000;
  text-align: left;
  font-weight: 600;
}

.article-header_details_date {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 5px;
}

.article-date_fulldetails {
  font-size: 12px;
  color: grey;
  font-weight: 400;
}

.share-icon {
  cursor: pointer;
}

.video-wrapper,
.image-wrapper {
  float: right;
  width: 30%;
  margin-left: 20px;
  margin-bottom: 20px;
  padding-top: 16.9%;
  position: relative;
  border: 3px solid #006769;
  background-color: #000;
  border-radius:7px;
}

.video-wrapper iframe,
.image-wrapper img {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the container while maintaining aspect ratio */
}
.article-text {
  font-size: 18px;
  line-height: 1.7;
  color: #000000;
}

.article-text p {
  margin-bottom: 0.8em;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {

  .full-article-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 2rem;
    padding-bottom: 7rem;
 
  }

  .article-title {
    font-size: 20px;
    margin-bottom: 0em;
    text-align: left;

  }

  .article-header_details_date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 12px;
    padding: 5px;
  }

  .article-date_fulldetails {
    flex-grow: 1;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }


  .article-header_details_date span {
    flex-shrink: 0;
 
  }

  .video-wrapper,
  .image-wrapper {
    float: none;
    width: 100%;
    margin-left: 0;
    padding-top: 56.25%;
  
  }

  .article-text {
    font-size: 1em;
    
  }

  .article-text p {
    margin-bottom: 1em;
    
  }
}