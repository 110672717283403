.articles-container {
  display: flex;
  gap: 16px;
  padding: 16px;
  width: 100%;
}

.article-card {
  width: calc(33.333% - 16px);
  background-color: var(--surface);
  border: 1px solid var(--on-surface);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s;
}

.article-card:hover {
  transform: scale(1.03);
}

.article-thumbnail {
  width: 200%;
  height: 200px;
  object-fit: cover;
}

.article-content {
  padding: 16px;
  color: var(--on-surface);
}

.article-description {
  margin-top: 8px;
  font-size: 0.9rem;
  color: var(--on-surface);
}

.article-authors {
  margin-top: 8px;
  font-size: 0.8rem;
  color: var(--on-primary-container);
}

.article-date {
  margin-top: 8px;
  font-size: 0.8rem;
  color: var(--on-surface-low);
}

.read-more {
  margin-top: 16px;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--on-primary-container);
  text-decoration: none;
}

/* General responsive adjustments */
@media (max-width: 768px) {
  .articles-container {
    flex-direction: column;
    background-color: var(--surface) !important;
  }

  .article-card {
    width: 100%; /* Make cards full width for small screens */
    border: 1px solid var(--on-surface);
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    transition: transform 0.2s;
  }

  .article-thumbnail {
    width: 100%;
    height: 150px; /* Adjust height for smaller screens */
    object-fit: cover;
  }

  .article-content {
    padding: 12px; /* Adjust padding inside the card */
  }

  .article-description {
    margin-top: 6px;
    font-size: 0.85rem; /* Slightly smaller font size for descriptions */
  }

  .article-authors {
    margin-top: 6px;
    font-size: 0.75rem;
  }

  .article-date {
    margin-top: 6px;
    font-size: 0.75rem;
  }

  .read-more {
    margin-top: 12px;
    font-size: 0.85rem;
  }
}
