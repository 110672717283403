

.forgot-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  
}

.forgot-box {
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.forgot-heading, .forgot-reset-heading {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #333;
}

.forgot-email-form, .forgot-otp-form, .forgot-reset-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.forgot-label {
  font-size: 1rem;
  color: #555;
}

.forgot-email-input, .forgot-otp-input, .forgot-password-input {
  width: 100%;
}

.forgot-button {
  padding: 0.75rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.forgot-button:disabled {
  background-color: #999;
  cursor: not-allowed;
}

.forgot-button:hover:not(:disabled) {
  background-color: #0056b3;
}

.forgot-success-message-container {
  text-align: center;
  margin-top: 1rem;
}

.forgot-success-message {
  color: #28a745;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.forgot-error-message {
  color: #dc3545;
  font-size: 1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.forgot-show-password {
  display: flex;
  align-items: center;
}

.forgot-show-password input[type="checkbox"] {
  margin-right: 0.5rem;
}


.center-btn-container {
  display: flex;
  justify-content: center; 
  margin-top: 2rem;
}

.forgot-btn__sent_otp {
  border-radius: 30px !important;
  background: rgb(3, 97, 51) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
  padding: 10px !important;
  width: 200px;
  display: flex;
  justify-content: center; 
  align-items: center; 
  text-align: center; 
}



.forgot-btn_verify_otp{
  border-radius: 30px !important;
  background: rgb(3, 97, 51) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
  padding: 10px !important;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
}

.forgot-btn__change_password{
  border-radius: 30px !important;
  background: rgb(3, 97, 51) !important;
  color: rgb(255, 255, 255) !important;
  font-weight: 600;
  padding: 10px !important;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center; 
}

.modal__gotoLogin_btn{
  border-radius: 30px !important;
  background-color: green !important;
  margin-right: 10px !important;
}

.modal__lastclose_btn {
  border-radius: 30px !important;
  background-color: rgb(128, 0, 0) !important;
  margin-left: 10px !important;
}

@media (max-width: 600px) {
  .forgot-box {
    padding: 1rem;
  }

  .forgot-heading, .forgot-reset-heading {
    font-size: 1.5rem;
  }

  .forgot-email-input, .forgot-otp-input, .forgot-password-input, .forgot-button {
    font-size: 0.875rem;
  }
}
