.heading::after{
content:"";
position:relative;
display: block;
height: 4px;
width:70px;
background-color: #007bff;
margin:15px auto 0px;
border-radius: 30px;
}


.fade-in {
  animation: fadeIn 1.2s ease-in-out;
}

.slide-in-left {
  animation: slideInLeft 1s ease-out;
}

.slide-in-right {
  animation: slideInRight 1s ease-out;
}

.fade-in-up {
  animation: fadeInUp 1.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.youtube-link-container {
  background-color: #f9fafb;
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.youtube-link-container:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.youtube-button {
  background-color: #007bff;
  color: #ffffff;
  border-radius: 9999px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.youtube-button:hover {
  background-color: #0056b3;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}



.special-content .certificate-button {
  font-size: 1.25rem; 
  padding: 0.75rem 1.5rem; 
  color: #ffffff; 
  background: linear-gradient(to right, #34d399, #10b981); 
  border: none; 
  border-radius: 0.5rem; 
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
  cursor: pointer; 
  transition: all 0.3s ease-in-out; 
}

.special-content .certificate-button:hover {
  transform: translateY(-2px); 
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background: linear-gradient(to right, #10b981, #059669); 
}

.special-content .certificate-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(16, 185, 129, 0.5); 
}
