.blog-preview-container {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  border-radius: 0.5rem;
  max-height: 100vh;
  font-family: var(--font-display);
}

@media (min-width: 1024px) {
  .blog-preview-container {
    flex-direction: row;
  }
}

.blog-toc {
  display: none;
  width: 25%;
  padding: 1.5rem;
  overflow-y: auto;
  position: sticky;
  top: 0;
  max-height: 100vh;
  z-index: 1;
  flex-shrink: 0;
}

@media (min-width: 1024px) {
  .blog-toc {
    display: block;
  }
}

.blog-toc-title {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: white;
}

.blog-toc-maximized .blog-toc-title {
  font-size: 1.75rem;
}

.blog-toc-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.blog-toc-item {
  margin-bottom: 0.5rem;
}

.blog-toc-link {
  color: #3b82f6; /* Tailwind blue-400 */
  text-decoration: none;
}

.blog-toc-link:hover {
  color: #10b981; /* Tailwind green-500 */
}

.blog-content {
  flex-grow: 1;
  padding: 1.5rem;
  overflow-y: auto;
}

.blog-title {
  margin-bottom: 1.5rem;
}

.blog-title-text {
  font-size: 1.75rem;
  font-weight: bold;
}

.blog-title-maximized {
  font-size: 2rem;
}

.blog-authors {
  margin-bottom: 1.5rem;
}

.blog-authors-text {
  color: white;
  font-size: 0.875rem;
}

.blog-author-link {
  color: #a8df8e;
  transition: color 0.2s;
}

.blog-author-link:hover {
  color: #16ff00;
}

.blog-author-name {
  color: #a8df8e;
}

.blog-content-text {
  text-align: justify;
  color: var(--text-on-surface-low);
  line-height: 1.7;
}

/* Content Styling */
.blog-content-text h2 {
  font-size: 24px;
  color: #f5f5f5;
}

.blog-content-text h3 {
  font-size: 22px;
  color: #f5f5f5;
}

.blog-content-text h4 {
  font-size: 20px;
  color: #f5f5f5;
}

.blog-content-text h5 {
  font-size: 18px;
  color: #f5f5f5;
}

.blog-content-text h6 {
  font-size: 16px;
  color: #f5f5f5;
}

.blog-content-text p {
  font-size: 16px;
  line-height: 1.7;
  color: white;
  padding: 5px;
  margin-left: 10px;
}

.blog-content-text a {
  color: lightblue;
  text-decoration: none;
}

.blog-content-text img {
  max-width: 100%;
  border-radius: 8px;
  margin: 10px auto;
  display: block;
}

.blog-content-text video {
  max-width: 100%;
  border-radius: 8px;
  margin: 10px auto;
  display: block;
}

.blog-content-text iframe {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  margin: 10px 0;
  display: block;
  aspect-ratio: 16 / 9;
  border: none;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.blog-content-text blockquote {
  border-left: 5px solid #9ec8b9;
  padding: 10px;
  font-style: italic;
  color: white;
  background-color: #092635;
  border-radius: 4px;
  text-align: left;
}

.blog-content-text ol {
  padding: 5px;
}

.blog-content-text ol li {
  margin: 10px 40px;
  color: white;
}

.blog-content-text ul {
  padding-left: 5px;
}

.blog-content-text ul li {
  margin: 10px 40px;
  color: white;
}

.blog-content-text table {
  width: 100%;
  border-collapse: collapse;
  margin: 10px 0;
}

.blog-content-text th,
.blog-content-text td {
  border: 1px solid #6eacda;
  text-align: center;
  padding: 10px;
  color: white;
}

.blog-content-text th {
  background-color: #0b192c;
  font-weight: bold;
}
