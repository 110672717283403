.portfolio-container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 5rem;
  padding-bottom: 6rem;
}

.portfoliopage_holding-and_sold-button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  padding: 10px;
  border-radius: 4px;
}

.holding_portfolio__button,
.sold_portfolio_button {
  padding: 15px 30px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  color: white;
  background-image: linear-gradient(to right, #333333, #555555);
  border-radius: 30px;
  transition: background-image 0.3s ease;
  margin-left: 10px;
  margin-right: 10px;
}

.holding_portfolio__button.active,
.sold_portfolio_button.active {
  background-image: linear-gradient(to right, rgb(55, 94, 55), rgb(85, 164, 45));
  border: 2px solid rgb(26, 202, 255);
}

.holding_portfolio__button:focus,
.sold_portfolio_button:focus {
  outline: none;
}

.empty-soldoutstock-message {
  padding: 20px;
  border: 2px dotted #007bff;
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  margin: 20px 0;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.empty-soldoutstock-message {
  text-align: center;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px 0;
}

.empty-soldmessage-icon-div {

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  margin-bottom: 15px;
}



.message-content {
  text-align: left;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  font-family: Arial, sans-serif;
}



.Portfolio-title {
  font-size: 2rem;
  text-align: center;
  color: #000000;
  font-weight: 800;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  animation: slideDownFadeIn 1.5s ease-out;
  padding: 10px;
  letter-spacing: 1px;
  line-height: 1.2;
  margin-top: 10px;
}

.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  border: 2px dashed #0077b6;
  border-radius: 15px;
  margin: 20px 0;
  background-color: #e0fbfc;
}

.welcome-title {
  font-size: 24px;
  font-weight: 700;
  color: #023e8a;
  margin-bottom: 15px;
}

.welcome-description {
  font-size: 18px;
  text-align: center;
  color: #03045e;
  margin-bottom: 25px;
}



@media (max-width: 768px) {
  .portfolio-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .input-icon-container {
    position: relative;
  }
  
  .input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid black !important;
    font-size: 1rem !important;
    height: 48px !important;
    width: 48px !important;
    border-radius: 50%;
    background-color: #34568B !important;
    color: white !important;
    padding: 10px;
  }
  

  .portfoliopage_holding-and_sold-button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 5px;
    border-radius: 4px;
  }


  .Portfolio-title {
    font-size: 25px;
    padding: 5px;
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .Portfolio-holding-tab {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
  }

  .List_Portfolio-sold_title {
    font-size: 20px;

  }

  .autocomplete-input {
    width: 250px;
  }

 
}