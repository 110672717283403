.chart-and-data-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

.chart-title_shariahdetailspage {
  text-align: center;
  margin: 2px 10px 10px;
  font-weight: bold;
  font-size: calc(16px + (22 - 16) * ((100vw - 300px) / (1600 - 300)));
  color: var(--on-surface);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
  letter-spacing: 0.5px;
  padding: 10px;
  font-size: 1rem;
}

.chart-section_dougnut_chart,
.data-section {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  
}

.chart-section_dougnut_chart {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  padding: 10px;
  
}

.data-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nonhalal_doughnutchart_title {
  font-size: calc(14px + (20 - 14) * ((100vw - 300px) / (1600 - 300)));
  color: var(--on-surface);
  margin-bottom: 10px;
  font-weight: 500;
}

.chart-section_dougnut_chart {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 300px;
  margin: auto;
  padding: 10px;
}

.data-section p {
  font-size: calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));
  color: #ff0000;
  font-weight: bold;
  border-radius: 7px;
  padding: 5px 10px;
}

@media (max-width: 768px) {
  .chart-title_shariahdetailspage {
    font-size: 14px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 5px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .chart-and-data-container {
    flex-direction: column;
  }

  .chart-section_dougnut_chart,
  .data-section {
    width: 100%;
    max-width: none;
    padding: 0px;
  }

  .chart-section_dougnut_chart {
    height: auto;
    padding: 0px;
  }

  .data-section {
    padding: 5px;
  }

  .nonhalal_doughnutchart_title {
    font-size: 14px;
  }

  .data-section p {
    font-size: 16px;
    padding: 5px;
  }
}

@media only screen and (max-width: 600px) {
  .doughnut-chart-container_shariahdetails_shariahpage {
    width: 100%;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .chart-title_shariahdetailspage {
    font-size: 14px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .chart-and-data-container {
    flex-direction: column;
  }

  .chart-section_dougnut_chart,
  .data-section {
    max-width: 100%;
    width: 100%;
  }

  .chart-section_dougnut_chart {
    height: auto;
    padding: 5px;
  }

  .nonhalal_doughnutchart_title {
    font-size: 16px;
  }

  .data-section p {
    font-size: 15px;
    padding: 5px;
  }
}
