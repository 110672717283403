body {
  font-family: "Roboto", sans-serif;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f4f4f9;
}

/* Container */
.privacy-container {
  max-width: 1200px;
  margin: 0px auto;
  padding: 40px;
  background-color: var(--surface);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  animation: fadeIn 0.6s ease-out;
}

/* Headings */
.policy-heading {
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 25px;
  color: var(--on-surface);
  text-align: center;
  position: relative;
  animation: fadeInDown 0.5s ease;
}

.policy-heading::after {
  content: "";
  height: 5px;
  width: 80px;
  display: block;
  margin: 20px auto 0;
  background-color: #2c7d7d;
  border-radius: 2px;
}

/* Section Titles */
.policy-titles {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 20px;
  color: var(--on-surface);
  position: relative;
  padding-bottom: 10px;
  border-bottom: 2px solid #56b5b5;
}

/* Paragraphs */
.policy-paragraph {
  font-size: 1.1rem;
  margin-bottom: 20px;
  text-align: justify;
  color: var(--on-surface-variant);
  line-height: 1.75;
}

/* Bullet Points */
.policy-bullet-list {
  margin-left: 40px;
  margin-bottom: 20px;
  color: var(--on-surface);
}

.policy-bullet-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--on-surface);
}

.policy-sub-bullet-list {
  margin-left: 20px;
  margin-bottom: 10px;
}

.policy-sub-bullet {
  font-size: 1rem;
  margin-bottom: 7px;
  color: var(--on-surface);
}

/* Animations */
.policy-section {
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.6s ease forwards;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
