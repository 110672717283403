
.achievements-container {
    display: flex;
    justify-content: space-between;
    height:auto;
    max-height: 900px;
   max-width: 1450px;
   width:100%;
    padding: 10px; 
}
.History-title {
    font-size: 24px; 
    font-weight: bold; 
    color: #333; 
    margin-bottom: 5px; 
    padding-top: 20px; 
    border-bottom: 1px solid #ddd;
    text-align: center; 
}

.view-mode-buttons__history {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px; 
    padding-left: 10px; 
}

.history__btn-style__target_Pice, .history__btn-style__stopLoss {
    padding: 5px 10px; 
    font-size: 14px; 
    font-weight: bold; 
    color: rgb(0, 0, 0); 
    border: none; 
    border-radius: 25px; 
    cursor: pointer; 
    outline: none; 
    transition: background-color 0.3s, transform 0.1s, box-shadow 0.3s; 
    background-color: #d3d1d1; 
    border: 2px solid black;
}

.history__btn-style__target_Pice.active, .history__btn-style__stopLoss.active {
    background-image: linear-gradient(to right, #66bb6a, #095b0d); 
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: white; 
    border: 2px solid rgb(66, 173, 255); 
}


.history__btn-style__target_Pice:not(.active):hover, .history__btn-style__stopLoss:not(.active):hover {
    background-image: linear-gradient(to right, #66bb6a, #095b0d63); 
    color:white;
}

.history_achievement-category {
    padding: 15px;
    border-radius: 10px;
    background-color: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.history__scrollable-table-container {
    max-height: 400px;
    overflow-y: auto;
    border-radius: 5px;
}

.archived-table {
    width: 100%;
    border-collapse: collapse;
}

.archived-table th {
    background-color: #000000;
    color: white;
    padding: 10px;
    text-align: center;
}

.history_header_compnyname, .history_header_percentage_Gain, .history_header_Annualized_Return, .history_header_percentage_Loss, .history_header_Annualized_Loss {
    text-align: left;
}

.archived-table .profit {
    color: #28a745; 
}

.archived-table .loss {
    color: #dc3545; 
}


.history_no-data-message {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: grey;
}


.targetPrice_title {
    text-align: center;
    padding: 10px;
    color: #ffffff !important;
    background-image: linear-gradient(to right, #304362, #0faa4d); 
}
.stoploss_title {
    text-align: center;
    padding: 10px;
    color: #ffffff !important;
    background-image: linear-gradient(to right, #f82d45, #20495e); 
}
.button-style {
    background-color: #4a90e2;
    color: white; 
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    font-size: 16px; 
    font-weight: bold; 
    cursor: pointer;
    outline: none; 
    transition: background-color 0.3s, transform 0.1s;
}

.button-style:hover {
    background-color: #3a78c3;
    transform: translateY(-2px);
}

.button-style:active {
    background-color: #3270b3; 
    transform: translateY(1px);
}


.archived-table {
    width: 100%;
    border-collapse: collapse;
}

.archived-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
}

.archived-ideas-container .archived-table thead th {
    background-color: #283347;
    color: #ffffff;
    text-align: center;
    padding: 7px;
    border-bottom: 2px solid #2d3748;
    font-size: 15px;
}

.archived-table tbody tr {
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.archived-table tbody tr:nth-child(even) {
    background-color: #edf2f7;
}

.archived-table tbody tr:hover {
    background-color: #dcf8e0;
}

.archived-table td {
    padding: 10px;
    border-bottom: 1px solid #cbd5e0;
    text-align: center;
    font-size: 14px;
}
.name_company{
    font-weight: 800;
}

.profit {
    color: #38a169;
    font-weight: bold;
}

.loss {
    color: #e53e3e;
    font-weight: bold;
}

.dialog-container {
    background-color: #ffffff; 
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); 
    border-radius: 8px; 
}

.dialog-title__details {
    background-color: #5f7b99; 
    color: white;
    padding: 15px;
    border-top-left-radius: 8px; 
    border-top-right-radius: 8px;
    font-size: 20px;
}

.dialog-content {
    padding: 20px;
    background-color: #f8f9fa; 
    border-bottom-left-radius: 8px; 
    border-bottom-right-radius: 8px;
}

.company_name_div{
    padding:10px;
    margin-bottom: 1rem;
    background-color: #fff; 
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); 
    border-radius: 5px;
    width:100%;
}

.first_name h6{
  font-size: 1rem;
    font-weight: bold;
}
.strong_company_name{
    color:black;
    font-size: 18px;
}

.company-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    text-align: left;
    text-decoration: none;
}
.target_companyname{
    text-decoration: none;
color:#2E8A99;
}

.item-label {
    color: #007bff; 
    font-weight: bold;
    display: block; 
    margin-bottom: 5px; 
}

.item-content {
    color: #000000; 
    font-size: 16px;
    font-weight: 500;
}
.item-content__profitgain{
    color: green; 
    font-size: 16px;
    font-weight: 500;
}
.item-content__return{
    color: green; 
    font-size: 16px;
    font-weight: 500;
}
.item-content__lossgain{
    color: red; 
    font-size: 16px;
    font-weight: 500;
}
.item-content__lossreturn{
    color: red; 
    font-size: 16px;
    font-weight: 500;
}
.rupees{
    color:#1D5B79;
    padding:5px;
    border-radius: 8px;
}
.days{
    color:rgb(191, 8, 8);
    padding:7px;
    border-radius: 8px;
}

.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
}

.left-item, .right-item {
    width: 48%; 
    padding: 10px;
    background-color: #fff; 
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1); 
    border-radius: 5px;
    
}
.left-item{
    margin-left: 11px;
}
.right-item{
    margin-right: 11px;
}
.remainder-stoploss {
    background-color: #f9f9f9; 
    border-left: 4px solid #007bff; 
    padding: 15px; 
    margin-top: 20px; 
    border-radius: 8px; 
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
    font-size: 16px;
    color: #000000; 
    background-color: #b4d5f85a;
}

.remainder-stoploss strong {
    font-weight: bold;
    color: #28a745; 
    margin-right: 5px; 
}


.back-button {
    background-color: #000000; 
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s; 
    width: 15%; 
    float: right; 
}

.back-button:hover {
    background-color: #218838; 
}

.no-data-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 20px;
    
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
}

.no-data-message p {
    color: #666; 
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
}

.no-data-message i {
    margin-right: 10px;
    color: grey; 
    font-size: 24px;
}

@media screen and (max-width: 768px) {
    .achievements-container {
        display: block; 
        max-width: 100%; 
        padding: 5px; 
    }

    .view-mode-buttons__history {
        flex-direction: row; 
        justify-content: center;
        gap: 5px; 
    }

    .history__btn-style__target_Pice, .history__btn-style__stopLoss {
        width: 45%; 
        padding: 5px 10px;
        font-size: 14px;
        border-radius: 8px; 
    }

    .history__btn-style__target_Pice.active, .history__btn-style__stopLoss.active {
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3); 
    }

    .history__btn-style__target_Pice:not(.active), .history__btn-style__stopLoss:not(.active) {
        background-color: #e0e0e0; 
    }

    .history__btn-style__target_Pice:not(.active):hover, .history__btn-style__stopLoss:not(.active):hover {
        background-image: linear-gradient(to right, #66bb6a, #095b0d); 
    }

    .History-title {
        font-size: 18px; 
        font-weight: bold;
        color: #333;
        margin-bottom: 0px;
        padding-top: 10px; 
        border-bottom: 1px solid #ddd;
        text-align: center;
    }
   
    .targetPrice_title {
        text-align: center;
        padding: 8px; 
        color: #ffffff;
        background-image: linear-gradient(to right, #363062, #5A4FCF);
        font-size: 15px;
    }
    .stoploss_title {
        text-align: center;
        padding: 8px;
        color: #ffffff;
        background-image: linear-gradient(to right, #ff6e7f, #6d92a6);
        font-size: 15px;
    }
    .single_stock_data.odd {
        border: 1px solid black;
        padding: 5px;
        margin-bottom: 3px;
        border-radius: 10px; 
        background: #FAF1E4;
    }

    .single_stock_data.even {
        border: 1px solid black;
        padding: 5px; 
        margin-bottom: 3px; 
        border-radius: 10px; 
        background: #EFF8FF;
    }
    .history-Date {
        font-size: 0.6em; 
        margin-bottom: 2px; 
        color: #333;
        width: 30%; 
        text-align: center;
        padding: 5px; 
        border-radius: 10px; 
        background-image: linear-gradient(to right, #6dd5ed, #2193b0);
        box-shadow: 0px 0px 2px #000;
        font-weight: bold;
    }
    .archived-list {
        list-style-type: none;
        margin: 0; 
        padding: 0; 
        text-decoration: none;
        width: 100%;
    }
   
    .history_list_compnyname {
        color: #1D5B79;
        text-decoration: none;
        padding: 3px;
        font-size: 0.7rem; 
        font-weight: 900;
        text-align: left;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    }
    .idea-stats {
        font-size: 0.6em;
        color: #666;
    }
    
    .stat-item {
        margin-right: 10px;
        display: inline-block; 
    }
    
    .stat-label {
        font-weight: bold; 
    }
    
    .stat-value {
        margin-left: 4px; 
    }

    .more-details {
        text-align: left;
        font-size: 0.6em; 
        color: #0f1114;
        cursor: pointer;
        margin-top: 3px; 
    }
        .dialog-container {
        background-color: #ffffff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2); 
        border-radius: 5px; 
    }

    .dialog-title__details h2 {
        font-size: 15px !important;
        color: #fafafa;
       
    }
    

    .dialog-content {
        padding: 10px; 
        background-color: #f8f9fa;
        border-bottom-left-radius: 5px; 
        border-bottom-right-radius: 5px; 
    }
    .company_name_div {
        padding: 5px; 
        margin-bottom: 5px; 
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
        border-radius: 5px;
        max-width: 100%;
        border:1px solid black;

    }

    .first_name h6 {
        font-size: 0.5rem; 
        font-weight: bold;
    }

    .strong_company_name {
        color: #007bff;
        font-size: 13px; 
    }

    .company-name {
        display: block; 
        font-size: 13px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: left;
        text-decoration: none;
    }
    .target_companyname {
        text-decoration: none;
        color: #2E8A99;
    }
    .item-label {
        color: #007bff;
        font-weight: bold;
        display: block;
        margin-bottom: 3px; 
        font-size: 11px;
        letter-spacing: normal;
    }

    .item-content, 
    .item-content__profitgain, 
    .item-content__return, 
    .item-content__lossgain, 
    .item-content__lossreturn {
        color: inherit; 
        font-size: 12px; 
        font-weight: 500;
    }
    .rupees, .days {
        padding: 3px; 
        border-radius: 5px; 
    }

    .row {
        display: block; 
        margin-bottom: 10px; 
    }

    .left-item, .right-item {
        width: 93%; 
        padding: 5px; 
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1); 
        border-radius: 5px;
        margin-bottom: 10px;
        border:1px solid black;
    }
    .left-item{
        margin-left: 10px;
        margin-right: 10px;
    }
    .right-item{
        margin-left: 10px;
        margin-right: 10px;
    }

    .remainder-stoploss {
        padding: 5px; 
        margin-top: 10px; 
        border-radius: 5px; 
        font-size: 11px; 
        background-color: #b4d5f85a;
        width:100%;
    }

    .remainder-stoploss strong {
        font-weight: bold;
        margin-right: 3px; 
    }

    .back-button {
        padding: 6px 10px; 
        font-size: 14px; 
        margin-top: 10px; 
        width: 30%; 
        float: none; 
        display: block; 
        margin-left: auto;
        margin-right: auto;
    }
    .archived-table thead th, .archived-table td {
        padding: 5px; 
        font-size: 11px; 
    }

    

}