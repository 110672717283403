.section {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 80px 64px;
  background-color: var(--surface);
}
.section__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1136px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Adjust the max-width as needed for your mobile breakpoint */
  .section {
    padding: 40px 16px; /* Reduced padding for smaller screens */
  }

  .section__container {
    max-width: 100%; /* Remove the maximum width constraint */
  }
}
