.holding_portfolio {
    width: 100%;
    max-width: auto;
    overflow-x: auto;
    overflow-y: auto;
    margin-bottom: 1rem;
}


.holding_portfolio {
    width: 100%;
    border-collapse: collapse;
}

.holding-portfolio-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
    padding: 10px 20px;
}


.holding_portfolio .MuiTableHead-root .MuiTableCell-head {
    position: sticky;
    top: 0;
    color: white !important;
    background-color: #2e3b4e !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: center;
    font-size: 2rem;
}

.holding_portfolio .tss-bd2lw8-MUIDataTableHeadCell-sortActive {
    color: white !important;
}


.profit-loss {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.profit-loss.green {
    color: green;
}

.profit-loss.red {
    color: red;
}


.business-status {
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    align-content: center
}



.Portfolio_stock-name-link {
    text-decoration: none;
    color: black;
    transition: color 0.3s;
    text-align: left;
    font-size: 1rem;
    font-weight: bold;
    padding: 0 10px;
    margin: 0 5px;
    white-space: nowrap;
}


.Portfolio_stock-name-link:hover {
    color: #007BFF;
}

.quantity-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.portfolio_current-price h4 {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.portfolio_company-price-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.portfolio_quantity-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.purgable-income-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.target-price-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.stop-loss-value {
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
}

.action-buttons {
    display: flex;
    gap: 10px;
}

.edit-icon,
.delete-icon {
    cursor: pointer;
    transition: color 0.3s;
    margin-top: 10px;
    margin-right: 15px;
}

.edit-icon:focus,
.delete-icon:focus,
.sellbtn:focus {
    outline: 2px solid #007BFF;
}

.edit-icon {
    font-size: 24px;
    padding: 2px;
    margin-top: 0px;
}

.edit-icon:hover {
    color: #03642f;
}

.delete-icon {
    color: red;
}

.delete-icon:hover {
    color: rgb(0, 0, 0);
}

.sell__button {
    padding: 5px;
    border: none;
    background-color: #bb1c1c;
    color: white;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100px
}

.sell__button:hover {
    background-color: #f90101;
}

.addnewportfolio__sideButton {
    display: block;
    padding: 15px 20px;
    background: linear-gradient(90deg, #375f3a, #4CAF50);
    color: #ffffff;
    border: none;
    border-radius: 30px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    width: 200px;
    position: relative;
    top: 0;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    outline: none;
    margin-bottom: 1rem;
    margin-left: 1rem;
}

.addnewportfolio__sideButton:hover {
    background: linear-gradient(90deg, #3f8f45, #43a047);
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.addnewportfolio__sideButton:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.addnewportfolio__sideButton:focus {
    border: 2px solid #43a047;
}

.delete-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.delete-dialog-content {
    background-color: #fff;
    padding: 25px;
    border-radius: 10px;
    width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.delete-dialog-content p {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #333;
}

.delete-dialog-buttons {
    display: flex;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
}

.delete-dialog-cancel,
.delete-dialog-confirm {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    font-weight: 500;
    transition: background-color 0.3s ease, transform 0.2s ease;
    cursor: pointer;
    outline: none;
}

.delete-dialog-cancel {
    background-color: #f44336;
    color: #fff;
}

.delete-dialog-confirm {
    background-color: #4CAF50;
    color: #fff;
}

.delete-dialog-cancel:hover {
    background-color: #e53935;
    transform: scale(1.05);
}

.delete-dialog-confirm:hover {
    background-color: #43a047;
    transform: scale(1.05);
}

@keyframes dotAnimation {
    0% {
        content: "Selling";
    }

    25% {
        content: "Selling.";
    }

    50% {
        content: "Selling..";
    }

    75% {
        content: "Selling...";
    }

    100% {
        content: "Selling";
    }
}

.sellingText::before {
    content: "Selling";
    animation: dotAnimation 2s infinite;
}

@media (max-width: 768px) {
    .holding_portfolio {
        overflow-x: scroll;
    }

    .holding-portfolio-title {
        font-size: 1.2rem;
    }

    .holding_portfolio .MuiTableHead-root .MuiTableCell-head {
        position: sticky;
        top: 0;
        font-size: 1rem;
    }


    .profit-loss {
        font-size: 0.9;
    }

    .business-status {
        font-size: 0.9;
    }


    .Portfolio_stock-name-link {
        font-size: 0.9;
    }

    .quantity-value {
        font-size: 0.9;
    }

    .portfolio_current-price h4 {
        font-size: 0.9;
    }

    .portfolio_company-price-value {
        font-size: 0.9;
    }

    .portfolio_quantity-value {
        font-size: 0.9;
    }

    .purgable-income-value {
        font-size: 0.9;
    }

    .target-price-value {
        font-size: 0.9;
    }

    .stop-loss-value {
        font-size: 0.9;
    }

    .addnewportfolio__sideButton {
        padding: 10px 20px;
        font-size: 16px;
        width: auto;
        top: 0;
        text-align: center;
        margin: 0 auto;
        display: block;
    }


}