.auth-container {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 6rem;
  padding-bottom: 7rem;
}

.choose-signup-login {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  padding: 10px;
}

.SignUp-or-login {
  cursor: pointer;
  font-weight: bold;
}

.blur-effect {
  filter: blur(4px);
  pointer-events: none;
  user-select: none;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
}

.google-login-btn {
  font-size: 1rem;
  color: #fff;
  background-color: #de5d21;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.2s;
  cursor: pointer;
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login-btn:hover {
  background-color: #357ae8;
  transform: scale(1.05);
}

.google-login-btn i,
.google-login-btn span {
  display: inline;
}

.google-login-btn i {
  margin-right: 0.5rem;
}

.google-login-btn span {
  padding: 0 0.5rem;
}

@media only screen and (max-width: 560px) {
  .google-login-btn {
    width: 100%; 
  }
}

