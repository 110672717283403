/* General Page Styles */
.about-page-container {
  font-family: "Roboto", sans-serif;
  animation: fadeIn 1s ease-in-out forwards;
  background-color: var(--surface);
}

.about-content-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 1.5rem;
  background-color: var(--surface);
  border-radius: 0.5rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Title Styles */
.about-page-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--on-surface);
  margin-bottom: 2.5rem;
  animation: slideInLeft 1s ease-in-out forwards;
}

.about-page-title::after {
  content: "";
  display: block;
  height: 4px;
  width: 80px;
  background-color: #2b6cb0;
  margin: 15px auto 0px;
}

/* Section Styles */
.about-section-container {
  margin-bottom: 3rem;
  animation: fadeInLeft 1s ease-in-out forwards;
}

.about-section-container:nth-child(odd) {
  animation: fadeInRight 1s ease-in-out forwards;
}

.about-section-heading {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--on-surface);
  margin-bottom: 1.5rem;
}

.about-section-paragraph {
  font-size: 1rem;
  line-height: 1.75rem;
  color: var(--on-surface-variant);
  margin-bottom: 1rem;
}

/* Footer Content */
.about-footer-text {
  font-size: 1rem;
  line-height: 1.75rem;
  color: var(--on-surface-variant);
  margin-top: 3rem;
  animation: fadeInUp 1s ease-in-out forwards;
}

.about-footer-text a {
  color: var(--primary);
}

.about-footer-text a:hover {
  color: #2b6cb0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
