.hero-search {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 700px;
   margin: 0 auto;
}
.hero-search__bar {
  display: flex;
  padding: 4px;
  border-radius: 26px;
  background: linear-gradient(
    to right,
    var(--surface-container),
    var(--surface-container-highest)
  );
}
.hero-search__search-icon {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: var(--on-surface-low);
  font-family: "Material Symbols Rounded";
  font-size: 24px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
}
.hero-search__input {
  display: flex;
  width: 100%;
  background-color: transparent;
}
.hero-search__input--font {
  font-family: "Funnel Sans";
  font-size: 20px;
  font-weight: 400;
  color: var(--on-surface-variant);
}
.hero-search__input--font::placeholder {
  font-family: "Funnel Sans";
  font-size: 20px;
  font-weight: 400;
  color: var(--on-surface-low);
}
.hero-search__input:focus {
  outline: none;
}
.hero-search__button {
  display: flex;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: var(--on-surface-inverse);
  font-family: "Material Symbols Rounded";
  font-size: 24px;
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 24;
  background-color: var(--surface-inverse);
  border-radius: 24px;
}

.search-bar-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 40px auto;
}

.input-icon-container {
  position: relative;
}

.input-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: 1px solid black !important;
  font-size: 1rem !important;
  height: 48px !important;
  width: 48px !important;
  border-radius: 50%;
  background-color: #34568b !important;
  color: white !important;
  padding: 10px;
}

.rounded-search-bar {
  width: 100%;
  padding: 15px 20px;
  font-size: 18px;
  border-radius: 50px;
  border: 2px solid #000000;
  transition: border 0.2s;
  outline: none;
  box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.1);
}

.rounded-search-bar::placeholder {
  color: transparent;
}

.animated-placeholder {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0.5;
  width: 80%;
  height: 50%;
  animation: slideIn 0.5s ease forwards;
}

.rounded-search-bar:focus + .animated-placeholder {
  opacity: 0;
  transform: translateY(-60%);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 0.5;
    transform: translateY(-50%);
  }
}

.rounded-search-bar:focus {
  border-color: #007bff;
  box-shadow: 2px 3px 16px rgba(0, 123, 255, 0.25);
}

.hero-search__container {
  display: flex;
  height: 0px;
  flex-direction: column;
  transition: all 300ms ease-in-out;
  opacity: 0;
}
.hero-search__container--expanded {
  display: flex;
  height: 300px;
  flex-direction: column;
  opacity: 1;
}

.hero-search__suggestions {
  display: flex;
  max-height: 100%;
  flex-direction: column;
  padding: 4px;
  gap: 8px;
  border-radius: 16px;
  background-color: var(--surface-container);
  overflow-y: scroll;
}

.hero-search__suggestions-item {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 14px;
  transition: all 100ms ease-in-out;
  cursor: pointer;
}

.hero-search__suggestions-item:hover {
  background-color: var(--surface-container-highest);
}

.suggestion-item.selected {
  background-color: #e9e9e9;
  font-weight: bold;
}

@media (max-width: 768px) {
  .search-bar-container {
    margin: 20px auto;
    max-width: 100%;
  }

  .input-icon-container {
    position: relative;
  }

  .input-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid black !important;
    font-size: 1rem !important;
    height: 48px !important;
    width: 48px !important;
    border-radius: 50%;
    background-color: #34568b !important;
    color: white !important;
    padding: 10px;
  }

  .rounded-search-bar {
    width: 100%;
    padding: 15px 50px;
    font-size: 18px;
    border-radius: 50px;
    border: 2px solid #000000;
    transition: border 0.2s;
    outline: none;
    box-shadow: 2px 3px 12px rgba(0, 0, 0, 0.1);
  }

  .rounded-search-bar::placeholder {
    color: transparent;
  }

  .animated-placeholder {
    left: 40px;
  }
}
