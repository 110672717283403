.ipo_detail_conatiner {
  padding: 4rem;
}

.ipo_detail_header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.ipo_company_name {
  margin: 0;
  white-space: nowrap;
  font-weight: 700;
}

.ipo_industry {
  margin: 0;
  font-size: 14px;
  border: 1px solid black;
  padding: 7px;
  border-radius: 25px;
  background-color: rgb(0, 0, 0);
  color: white;
}

.ipoView_postdate_ipodetails_data {
  font-size: 12px;
  text-align: end;
  margin-top: 15px;
  color: #515151;
  font-weight: 400;
}

.ipo_price_div {
  border: 1px solid black;
  border-radius: 25px;
  background: linear-gradient(145deg, #e6e6e6, #ffffff);
  box-shadow: 8px 8px 15px #aaaaaa, -8px -8px 15px #ffffff;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
}

.ipo_price_div:hover {
  transform: translateY(-5px);
  box-shadow: 10px 10px 20px #aaaaaa, -10px -10px 20px #ffffff;
}

.financialOverviewContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.financialOverviewContainer > div {
  display: flex;
  align-items: center;
}

.priceLabel,
.launchDateLabel,
.profitForecastLabel,
.complianceStatusLabel {
  margin: 0 5px;
  font-size: 15px;
}

.priceData,
.launchDateData,
.profitForecastData,
.complianceStatusData {
  color: green;
  font-size: 15px;
}

.businessModel_ipo {
  background-color: #ffffff;
  border: 1px solid #c8e6ef;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
}

.businessModel_heading {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
}

.businessModel_detail {
  color: #000000;
  font-size: 15px;
  margin-bottom: 2px;
  line-height: 1.5;
  margin-left: 20px;
}

.businessModel_detail-1 {
  font-size: 15px;
  padding: 5px;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 400;
}

.businessModel_detail-2 {
  font-size: 15px;
  padding: 5px;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 400;
}

.businessModel_detail-3 {
  font-size: 15px;
  padding: 5px;
  background: #ffffff;
  border-radius: 5px;
  font-weight: 400;
}

.Investment_prospective_ipo {
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 5px;
  font-family: "Helvetica", sans-serif;
}

.Investment_Prospective_ipo {
  color: #2a2a2a;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.Investment_prospective_ipo p {
  color: #000000;
  line-height: 1.5;
  text-align: justify;
  margin: 0;
  font-weight: 400;
  font-size: 15px;
  margin-left: 20px;
}

.shariah_CompliantStatus_ipo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid #ddd;
  margin-top: 0px;
  position: relative;
}
/* 
  .shariah_status_ipo_details{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  } */

.shariah_status_ipo_details,
.Finsha ating_ipodetails {
  flex: 1;
  padding: 10px;
}

.shariah_status_ipo_details {
  margin-right: 0px;
}
.Finsha ating_ipodetails {
  margin-left: 10px;
}

.Finsha ating_ipodetails_title {
  font-size: 24px;
  text-align: left;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 25px;
}

.details_ipo_shaiahStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 15%;
  margin-right: 10px;
}

.button_ipo_detailsshariahstatus {
  padding: 8px 13px;
  background-color: #2b544c;
  color: white;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease;
  margin-top: 150px;
}

.button_ipo_detailsshariahstatus:hover {
  background-color: #0056b3;
}

.financial_condition_screening {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 10px;
}

.financial_condition_title {
  color: #2a2a2a;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.financial_condition_row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

.financial_condition_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  margin-right: 10px;
  border-radius: 6px;
  padding: 10px;
}

.financial_condition_item:last-child {
  margin-right: 0;
}

.financial_condition_label {
  font-weight: bold;
  font-size: 15px;
  margin-right: 5px;
}

.financial_condition_data {
  font-size: 15px;
  text-align: left;
  font-weight: 700;
  color: red;
}

.financial_condition_item-5 {
  flex-basis: 100%;
  margin-right: 0;
}

.ValutionSection {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 10px;
}

.Valution_title {
  color: #2a2a2a;
  margin-bottom: 8px;
  font-size: 20px;
  font-weight: 700;
}

.valutionRatio_row {
  display: flex;
  flex-wrap: wrap;
  gap: 120px;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 20px;
}

.valutionRatio_row_item {
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 4px;
}

.valutionRatio_row_label {
  font-weight: bold;
  margin-right: 5px;
  font-size: 15px;
}

.valutionRatio_row_data {
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  background-image: linear-gradient(to right, #6dd5ed, #2193b0);
  border: 1px solid #dcdcdc;
  border-radius: 6px;
  padding: 6px;
  width: auto;
  min-width: 20px;
  white-space: nowrap;
}

.financial_section {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 20px;
}

.section_header {
  color: #2a2a2a;
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.growth_metric_row {
  display: flex;
  gap: 50px;
  margin-left: 20px;
}

.growth_metric_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.growth_metric_sales {
  align-self: flex-start;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 6px;
  width: 50%;
}

.growth_metric_profit {
  align-self: flex-end;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 6px;
  width: 50%;
}

.growth_metric_label {
  font-weight: 500;
  margin-bottom: 5px;
}

.growth_metric_data {
  font-size: 15px;
  color: red;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px;
  width: 100px;
  text-align: center;
  background: rgb(180, 255, 220);
  font-weight: 500;
}

.financial_operational_section {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 10px;
}

.operational_section_header {
  font-weight: bold;
  margin-bottom: 10px;
}

.operational_ratio_row {
  display: flex;
  gap: 50px;
  margin-left: 20px;
}

.operational_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.receivables_turnover_item {
  align-self: flex-start;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 6px;
  width: 50%;
}

.inventory_turnover_item {
  align-self: flex-end;
  background-color: #ffffff;
  border: 1px solid #dcdcdc;
  padding: 5px;
  border-radius: 6px;
  width: 50%;
}

.receivables_turnover_label,
.inventory_turnover_label {
  font-weight: 500;
  margin-bottom: 5px;
}

.receivables_turnover_data,
.inventory_turnover_data {
  font-size: 15px;
  color: red;
  border: 1px solid black;
  border-radius: 25px;
  padding: 5px;
  width: 100px;
  text-align: center;
  background: rgb(180, 255, 220);
  font-weight: 500;
}

.cashFlow_section_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0;
}

.Cash_flow_title {
  color: #2a2a2a;
  margin-bottom: 3px;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 10px;
}

.cashFlow_row {
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin-bottom: 10px;
}

.cashFlow_item {
  display: flex;
  align-items: center;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  padding: 7px;
  border-radius: 6px;
}

.cashFlow_label {
  font-weight: bold;
  margin-right: 10px;
  text-align: center;
}

.cashFlow_data {
  font-size: 15px;
  color: red;
  border-radius: 25px;
  padding: 5px;
  width: 100px;
  text-align: center;
  font-weight: 500;
}

.cashFlow_left {
  justify-content: flex-start;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  width: 50%;
  margin-right: 30px;
}

.cashFlow_right {
  justify-content: flex-end;
  background-color: #f9f9f9;
  border: 1px solid #dcdcdc;
  width: 50%;
}

@media only screen and (max-width: 600px) {
  .ipo_detail_conatiner {
    margin-top: 5rem;
    width: 100%;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .ipo_detail_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .ipo_company_name {
    font-size: 18px;
    white-space: normal;
  }

  .ipo_industry {
    font-size: 12px;
    padding: 5px;
    border-radius: 15px;
  }

  .ipoView_postdate_ipodetails_data {
    font-size: 10px;
    text-align: start;
    margin-top: 2px;
  }

  .ipo_price_div {
    flex-direction: row;
    padding: 0px;
  }

  .financialOverviewContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .financialOverviewContainer > div {
    margin-bottom: 10px;
  }

  .priceLabel,
  .launchDateLabel,
  .profitForecastLabel,
  .complianceStatusLabel,
  .priceData,
  .launchDateData,
  .profitForecastData,
  .complianceStatusData {
    font-size: 14px;
  }

  .shariah_CompliantStatus_ipo {
    flex-direction: column;
    border: 1px solid #ddd;
    margin-top: 0;
    position: relative;
  }

  .shariah_status_ipo_details,
  .Finsha ating_ipodetails {
    flex: none;
    width: 100%;
    padding: 10px;
    margin-right: 0;
    margin-left: 0;
  }

  .Finsha ating_ipodetails_title {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 15px;
    text-align: center;
  }

  .details_ipo_shaiahStatus {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-right: 0;
  }

  .button_ipo_detailsshariahstatus {
    font-size: 11px;
    padding: 6px 10px;
    margin-top: 10px;
    border-radius: 25px;
  }
  .ValutionSection {
    border-radius: 4px;
    padding: 5px;
  }

  .Valution_title {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .valutionRatio_row {
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }

  .valutionRatio_row_item {
    display: block;
    padding: 5px;
    margin-bottom: 5px;
  }

  .valutionRatio_row_label,
  .valutionRatio_row_data {
    font-size: 14px;
    display: block;
    text-align: left;
    margin-bottom: 3px;
  }

  .valutionRatio_row_data {
    background-image: linear-gradient(to right, #6dd5ed, #2193b0);
    padding: 4px;
    min-width: auto;
    white-space: normal;
  }

  .cashFlow_section_container {
    border-radius: 6px;
    padding: 10px;
    margin: 5px 0;
  }

  .Cash_flow_title {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .cashFlow_row {
    flex-direction: column;
    width: 100%;
    margin-bottom: 8px;
  }

  .cashFlow_item {
    justify-content: flex-start;
    width: 100%;
    margin-right: 0;
    margin-bottom: 5px;
    border: none;
    padding: 5px;
  }

  .cashFlow_label {
    font-weight: bold;
    margin-right: 0;
    text-align: left;
  }

  .cashFlow_data {
    font-size: 15px;
    color: red;
    border-radius: 6px;
    padding: 5px;
    width: 100%;
    text-align: left;
    font-weight: 500;
  }

  .cashFlow_left,
  .cashFlow_right {
    justify-content: flex-start;
    width: 100%;
    margin-right: 0;
    border: none;
  }
}
