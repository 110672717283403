/* Fade In Animation */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Fade In Down Animation */
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Pulse Animation */
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Transform Animation */
@keyframes transform {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.faq-head::after{
  content:"";
  display: block;
  height:4px;
  width:80px;
  margin:15px auto 0;
  background: #4f89d0;
}

/* Styling for Search Input */
.input {
  transition: all 0.3s ease;
}
.input:focus {
  border-color: #3b82f6; /* Blue-500 */
  box-shadow: 0 0 0 4px rgba(59, 130, 246, 0.3); /* Blue-500 with opacity */
}

/* Question List Styling */
.question-list li {
  animation: fadeInDown 0.5s ease-out;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.question-list li:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

/* Pagination Button Styles */
.pagination-btn {
  transition: all 0.3s ease;
}
.pagination-btn:hover {
  background-color: #2563eb; /* Blue-700 */
  transform: scale(1.05);
}

/* Answer Visibility Transition */
.answer {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}
.answer.visible {
  max-height: 1000px; /* Large enough to cover content */
  opacity: 1;
}

/* Enhanced Button Styles */
/* button {
  outline: none;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

button:hover {
  background-color: #1e40af; /* Darker blue */
  /*color: #ffffff;  White text */
