.IpoComponent {
  width: 100%;
  background-color: var(--surface);
}

.ipo_little_detail:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.ipo_little_detail_link {
  text-decoration: none;
}


.ipo_detail_rating_view {
  margin-left: 10px;
}


.no-ipos-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  max-width: 100%;
  margin: 10px auto;
  backgound-color: var(--surface);
}

.no-ipos-message {
  font-size: 18px;
  color: var(--on-surface);
  margin-bottom: 10px;
}

.no-ipos-calendar-icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  color: #33db33;
  margin-bottom: 10px;
}

.no-ipos-box-icon {
  font-size: 60px;
  color: #000000;
  margin-bottom: 20px;
}


.no-ipos-bullhorn-icon,
.no-ipos-chart-icon {
  font-size: 24px;
  color: #f39c12;
  margin-right: 10px;
}

.new-ipo-ribbon {
  position: absolute;
  width: 50px;
  top: -15px;
  right: 16px;
  background: linear-gradient(45deg, #9bdea5, #bff3bf);
  color: var(--on-surface);
  padding: 4px 8px;
  font-size: 11px;
  font-weight: bold;
  border-radius: 5px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  transform: rotateZ(-17deg);
}

.new-ipo-ribbon span {
  display: inline-block;
}

@keyframes ribbon-text-animation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 600px) {
  .IpoComponent {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
  }

  .Ipo_data_conainer {
    justify-content: center;
    padding: 10px;
  }

  .ipo_little_detail {
    flex: 0 1 100%;
    margin-right: 0;
    margin-bottom: 5px;
  }

  .ipo_component_companyname {
    font-size: 18px;
  }

  .ipo_price_detail,
  .ipo_label {
    font-size: 14px;
  }

  .ipo_detail_rating_view {
    margin-left: 0px;
  }
}


::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* color of the scrollbar thumb */
  border-radius: 10px; /* roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* creates padding around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* color of the scrollbar thumb when hovered */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For Internet Explorer and older Edge versions */
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

::-ms-scrollbar {
  width: 12px;
}

::-ms-scrollbar-track {
  background: #f1f1f1;
}

::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

::-ms-scrollbar-thumb:hover {
  background: #555;
}
