:root{
    --primary-5: #03170D;
    --primary-10: #052E1A;
    --primary-15: #084526;
    --primary-20: #0A5C33;
    --primary-30: #0F8A4D;
    --primary-40: #14B866;
    --primary-50: #1AE580;
    --primary-60: #47EB99;
    --primary-70: #75F0B2;
    --primary-80: #A3F5CC;
    --primary-85: #BAF7D9;
    --primary-90: #D1FAE5;
    --primary-95: #E8FCF2;

    --neutral-0: #000000;
    --neutral-10: #191919;
    --neutral-12: #1F1F1F;
    --neutral-14: #242424;
    --neutral-16: #292929;
    --neutral-18: #2E2E2E;
    --neutral-20: #333333;
    --neutral-30: #4D4D4D;
    --neutral-40: #666666;
    --neutral-50: #808080;
    --neutral-60: #999999;
    --neutral-70: #B2B2B2;
    --neutral-80: #CCCCCC;
    --neutral-90: #E5E5E5;
    --neutral-92: #EBEBEB;
    --neutral-94: #F0F0F0;
    --neutral-96: #F5F5F5;
    --neutral-98: #FAFAFA;
    --neutral-100: #FFFFFF;
}

[data-theme="light"]{

    --icon-cirle: var(var(--primary-60));
    --icon-rect: var(var(--neutral-10));


    --neutral: var(--neutral-10);
    --on-neutral: var(--neutral-90);

    --neutral-container: var(--neutral-90);
    --on-neutral-container: var(--neutral-10);

    --error: hsl(0, 80%, 40%);
    --on-error: hsl(0, 80%, 90%);
    --error-container: hsl(0, 80%, 90%);
    --on-error-container: hsl(0, 80%, 20%);
/* this is the bg color  */
    --primary: var(--primary-30);
    /* this is the text color for  */
    --on-primary: var(--primary-90);

    --primary-variant: var(--primary-40);
    --on-primary-variant: var(--neutral-100);

    --primary-container: var(--primary-85);
    --on-primary-container: var(--primary-20);

    --primary-fixed: var(--primary-50);
    --on-primary-fixed: var(--primary-10);
/* surface is for backgound color */
    --surface: var(--neutral-100);
    /* onsurface is for the text noraml color */
    --on-surface: var(--primary-10);
    /* on surface varient is for the lighter text */
    --on-surface-variant: var(--neutral-20);
    /* on suurface low is for the disbaled text or lightest color */
    --on-surface-low: var(--neutral-50);

    --surface-inverse: var(--neutral-14);
    --on-surface-inverse: var(--neutral-100);

    /* card csss all this are background color from higest to lowest */
    --surface-container-lowest: var(--neutral-98);
    --surface-container-low: var(--neutral-96);
    --surface-container: var(--neutral-94);
    --surface-container-high: var(--neutral-92);
    --surface-container-highest: var(--neutral-90);
}

[data-theme="dark"]{

    --icon-cirle: var(var(--primary-40));
    --icon-rect: var(var(--neutral-100));

    --neutral: var(--neutral-90);
    --on-neutral: var(--neutral-10);

    --neutral-container: var(--neutral-20);
    --on-neutral-container: var(--neutral-90);

    --error: hsl(0, 80%, 70%);
    --on-error: hsl(0, 80%, 20%);
    --error-container: hsl(0, 80%, 20%);
    --on-error-container: hsl(0, 80%, 90%);

    --primary: var(--primary-70);
    --on-primary: var(--neutral-10);

    --primary-variant: var(--primary-60);
    --on-primary-variant: var(--primary-10);

    --primary-container: var(--primary-15);
    --on-primary-container: var(--primary-80);

    --primary-fixed: var(--primary-50);
    --on-primary-fixed: var(--neutral-100);

    --surface: var(--neutral-10);
    --on-surface: var(--primary-95);
    --on-surface-variant: var(--neutral-80);
    --on-surface-low: var(--neutral-60);

    --surface-inverse: var(--neutral-14);
    --on-surface-inverse: var(--neutral-90);

    --surface-container-lowest: var(--neutral-12);
    --surface-container-low: var(--neutral-14);
    --surface-container: var(--neutral-16);
    --surface-container-high: var(--neutral-18);
    --surface-container-highest: var(--neutral-20);
}

.icon-circle{
    fill: var(--icon-circle);
}
.icon-rect{
    fill: var(--icon-rect);
}