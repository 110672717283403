.CompanyDetails-pb {
  font-size: 1rem;
    color: #000000;
    font-weight: 600;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  @media (max-width: 600px) {
    .CompanyDetails-pb {
      font-size: 0.8rem; 
      color: #000000;
  color: rgb(18, 3, 95);
    }
  }

